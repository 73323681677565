import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, Tooltip } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useRecordContext } from 'react-admin';

const TogglePreCadastro = () => {
  const record = useRecordContext();
  if (!record) return <div />;
  const [disabled, setDisabled] = useState(record.seen);
  const token = localStorage.getItem('token');

  const config = {
    headers: {
      company: record.company,
      authorization: `Bearer ${token}`,
    },
  };

  const handleToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/toggleMidiaSocial/${record.id}`,
        config,
      )
      .then((res) => {
        setDisabled(res.data);
      });
  };

  return (
    <div>
      <Button style={{ color: 'black' }} onClick={handleToggle}>
        {disabled ? (
          <Tooltip title="Habilitar Mídia Social">
            <VisibilityOffIcon />
          </Tooltip>
        ) : (
          <Tooltip title="Desabilitar Mídia Social">
            <VisibilityIcon />
          </Tooltip>
        )}
      </Button>
    </div>
  );
};

export default TogglePreCadastro;
