import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  Form,
  CreateBase,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

function CaracteristicaRapida({ onChange, source }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { isLoading }] = useCreate('characteristics');
  const notify = useNotify();
  const { setValue } = useFormContext();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      'characteristics',
      { data: values },
      {
        onSuccess: (data) => {
          setValue(source, data.id);
          onChange(data);
          setShowDialog(false);
        },
        onError: () => {
          notify('Já existe uma Característica com esse nome!', 'warning');
        },
      },
    );
  };

  return (
    <>
      <Button
        onClick={handleClick}
        label="ra.action.create"
        variant="outlined"
        style={{ marginBottom: '10px' }}
      >
        <AddIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Criar Caracteristica"
      >
        <DialogTitle>Criar Caracteristica</DialogTitle>
        <CreateBase
          resource="characteristics"
          id="characteristics"
          redirect={false}
        >
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <TextInput
                label="Nome"
                source="name"
                validate={required()}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                label="ra.action.cancel"
                onClick={handleCloseClick}
                disabled={isLoading}
              >
                <CancelIcon />
              </Button>
              <SaveButton disabled={isLoading} />
            </DialogActions>
          </Form>
        </CreateBase>
      </Dialog>
    </>
  );
}

export default CaracteristicaRapida;
