import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LaunchIcon from '@mui/icons-material/Launch';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  SelectInput,
  required,
  useRecordContext,
  ListButton,
  DeleteButton,
  Toolbar,
  SaveButton,
  usePermissions,
} from 'react-admin';

import ToggleMidiaSocial from './toggleMidiaSocial';

import './style.css';

const SocialTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return <span>Mídia social {record ? `"${record.name}"` : ''}</span>;
};

const SocialFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const socialMediaIcons = [
  { id: 'FacebookIcon', icon: <FacebookIcon />, name: 'Facebook' },
  { id: 'InstagramIcon', icon: <InstagramIcon />, name: 'Instagram' },
  { id: 'LinkedInIcon', icon: <LinkedInIcon />, name: 'LinkedIn' },
  { id: 'TwitterIcon', icon: <TwitterIcon />, name: 'Twitter' },
  { id: 'YouTubeIcon', icon: <YouTubeIcon />, name: 'YouTube' },
];

const IconField = () => {
  const record = useRecordContext();
  if (!record) return <div />;

  return (
    <span className="iconesMidiasSociais">
      {record.icon} {record.name}
    </span>
  );
};

const UrlField = () => {
  const record = useRecordContext();
  if (!record) return <div />;

  return (
    <a
      href={record.link}
      target="_blank"
      rel="noopener noreferrer"
      className="linkIconesMidiasSociais"
    >
      {record.link}
      <LaunchIcon className="iconeLinkMidasSociais" />
    </a>
  );
};

const IconDisplay = () => {
  const record = useRecordContext();
  if (!record) return <div />;

  return (
    <span className="iconesMidiasSociais">
      {socialMediaIcons.find((x) => x.id === record.icon).icon}
    </span>
  );
};

export const Social = (props) => (
  <List
    perPage={25}
    title="Mídias Sociais"
    filters={<SocialFilter />}
    {...props}
  >
    <Datagrid>
      <ToggleMidiaSocial label="Ativo" source="disabled" {...props} />
      <TextField label="Nome" source="name" />
      <IconDisplay label="Ícone" source="icon" />
      <UrlField label="Link" source="link" />
      <EditButton />
    </Datagrid>
  </List>
);

const Infos = () => (
  <div>
    <TextInput
      label="Nome"
      validate={required()}
      source="name"
      style={{ marginRight: '10px', marginTop: '8px' }}
    />
    <SelectInput
      source="icon"
      label="Ícone"
      choices={socialMediaIcons}
      validate={required()}
      optionText={<IconField />}
    />
  </div>
);

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    className="botoesUpdate"
    style={{ justifyContent: 'space-between' }}
  >
    <div
      style={{
        width: '250px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton alwaysEnable />
      <ListButton
        size="medium"
        variant="outlined"
        label="cancelar"
        icon={<CloseIcon />}
      />
    </div>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_MIDIA_SOCIAL_DELETE')) && (
        <DeleteButton size="medium" />
      )}
  </Toolbar>
);

export const SocialEdit = (props) => {
  const permissions = usePermissions();

  return (
    <Edit redirect="list" title={<SocialTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <Infos />
        <TextInput label="Link" validate={required()} source="link" fullWidth />
      </SimpleForm>
    </Edit>
  );
};

export const SocialCreate = (props) => {
  const permissions = usePermissions();

  return (
    <Create redirect="list" {...props} title="Nova Mídia Social">
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <Infos />
        <TextInput label="Link" validate={required()} source="link" fullWidth />
      </SimpleForm>
    </Create>
  );
};

export default Social;
