import {
  RichTextInput,
  RichTextInputToolbar,
  FormatButtons,
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
} from 'ra-input-rich-text';
import React from 'react';
import {
  TextInput,
  ImageInput,
  BooleanInput,
  FileInput,
  DateInput,
  required,
  useNotify,
  ImageField,
} from 'react-admin';

import FilePreview from '../../../components/FilePreview';
import FilePreviewOne from '../../../components/FilePreviewOne';
import ImagePreview from '../../../components/ImagePreviewWithShowFirst';
import { FILE_MAX_ZIZE, IMAGE_MAX_SIZE } from '../../../helpers/constants';

const File = ({ label }) => {
  const notify = useNotify();

  return (
    <FileInput
      label={label}
      validate={required()}
      multiple
      source="docs"
      options={{
        onDropRejected: (res) => {
          if (res[0].errors[0].code === 'file-too-large') {
            notify('Arquivo excede 35MB', { type: 'warning' });
          }
        },
      }}
      maxSize={FILE_MAX_ZIZE}
      accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.presentation"
    >
      <FilePreview />
    </FileInput>
  );
};

const FileOne = ({ label }) => {
  const notify = useNotify();
  return (
    <FileInput
      label={label}
      validate={required()}
      source="docs"
      options={{
        onDropRejected: (res) => {
          if (res[0].errors[0].code === 'file-too-large') {
            notify('Arquivo excede 35MB', { type: 'warning' });
          }
        },
      }}
      maxSize={FILE_MAX_ZIZE}
      accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.presentation"
    >
      <FilePreviewOne />
    </FileInput>
  );
};

const Image = ({ label }) => {
  const notify = useNotify();
  return (
    <ImageInput
      accept="image/*"
      source="pictures"
      options={{
        onDropRejected: (res) => {
          if (res[0].errors[0].code === 'file-too-large') {
            notify('Arquivo excede 35MB', { type: 'warning' });
          }
        },
      }}
      maxSize={IMAGE_MAX_SIZE}
      multiple
      validate={required()}
      label={label}
    >
      <ImagePreview />
    </ImageInput>
  );
};

const ImageOne = ({ label }) => {
  const notify = useNotify();
  return (
    <ImageInput
      accept="image/*"
      source="pictures"
      options={{
        onDropRejected: (res) => {
          if (res[0].errors[0].code === 'file-too-large') {
            notify('Arquivo excede 35MB', { type: 'warning' });
          }
        },
      }}
      maxSize={IMAGE_MAX_SIZE}
      validate={required()}
      label={label}
    >
      <ImageField source="src" label="Imagem" />
    </ImageInput>
  );
};

const Text = ({ label, source }) => (
  <TextInput label={label} source={source} validate={required()} fullWidth />
);

const Boolean = ({ label, source }) => (
  <BooleanInput label={label} source={source} validate={required()} />
);

const RichText = ({ label, source }) => (
  <RichTextInput
    toolbar={
      <RichTextInputToolbar>
        <LevelSelect />
        <AlignmentButtons />
        <FormatButtons />
        <ColorButtons />
        <ListButtons />
        <LinkButtons />
        <QuoteButtons />
        <ClearButtons />
      </RichTextInputToolbar>
    }
    label={label}
    source={source}
    fullWidth
    validate={required()}
  />
);

const Number = ({ label, source }) => (
  <TextInput
    type="number"
    label={label}
    source={source}
    validate={required()}
    parse={(value) => {
      if (value.length > 1 && value[0] === '0') {
        return value.slice(1);
      }
      if (value <= 1) return 1;
      return value;
    }}
  />
);

const Date = ({ label, source }) => (
  <DateInput validate={required()} label={label} source={source} />
);

export {
  Text,
  Number,
  RichText,
  Image,
  File,
  Boolean,
  Date,
  ImageOne,
  FileOne,
};
