import { Editor } from '@tinymce/tinymce-react';
import React, { useRef } from 'react';
import { useInput } from 'react-admin';

export const EditorBlog = (props) => {
  const editorRef = useRef(null);

  const { onChange, onBlur, value, ...rest } = props;
  // field: { value, name, onChange, onBlur, ref}
  const {
    field,
  } = useInput({
    onChange,
    onBlur,
    ...props,
  });

  const handleOnChange = () => {
    if (editorRef.current) {
      const editorValue = editorRef.current.getContent();
      console.log(editorValue);
      field.onChange(editorValue);
    }
  };

  return (
    <>
      {/* <button onClick={log}>show</button> */}
      <Editor
        apiKey='c1qk5x0joedqvp9pys2igbdvofm8258y10bklor7xeslzqog'
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={field.value}
        onBlur={handleOnChange}
        init={{
          height: 500,
          width: '100%',
          menubar: '',
          plugins: 'advlist autolink lists link image imagetools table charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media paste help wordcount importcss emoticons',
          toolbar: 'blocks undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist outdent indent | formatselect | table image media | removeformat code specialcharacter emoticons fullpage checklist',
          content_style:  'body { font-family:Helvetica,Arial,sans-serif; font-size:16px;} ' +
                          'img[style*="float: left"] {margin-right: 20px;} ' +
                          'img[style*="float: right"]{margin-left: 20px;}  ' +
                          'table[style*="margin-right: auto"]{ float: left; margin-right: 20px;} ' +
                          'table[style*="margin-left: auto"]{ float: right; margin-left: 20px;}',
          content_css: './index.css',
          image_caption: true,
          language: 'pt_BR',
        }}
        {...rest}
      />
    </>
  );
};
