/* eslint-disable import/prefer-default-export */
/**
 *
 * @param {JSX.Element} * Todos os elementos passados tem de ser do tipo JSX.Element
 * @returns {Array} Array de Objects com os elementos passados, nome e id
 */
const sectionChoices = ({
  Text,
  Number,
  RichText,
  Image,
  File,
  ImageOne,
  FileOne,
  Boolean,
  Date,
}) => [
  { id: 'text', name: 'Input de Texto', element: Text },
  { id: 'number', name: 'Input de Número', element: Number },
  { id: 'richText', name: 'Input de RichText', element: RichText },
  { id: 'images', name: 'Input de Imagens', element: Image },
  { id: 'files', name: 'Input de Arquivos', element: File },
  { id: 'image', name: 'Input de Imagem', element: ImageOne },
  { id: 'file', name: 'Input de Arquivo', element: FileOne },
  { id: 'boolean', name: 'Input de Boolean', element: Boolean },
  { id: 'date', name: 'Input de Data', element: Date },
];

/* eslint-disable import/prefer-default-export */
/**
 *
 * @param {JSX.Element} * Todos os elementos passados tem de ser do tipo JSX.Element
 * @returns {Array} Array de Objects com os elementos passados, nome e id
 */
const productStructChoices = ({
  Text,
  Number,
  RichText,
  Boolean,
  Date,
}) => [
  { id: 'text', name: 'Input de Texto', element: Text },
  { id: 'number', name: 'Input de Número', element: Number },
  { id: 'richText', name: 'Input de RichText', element: RichText },
  { id: 'boolean', name: 'Input de Boolean', element: Boolean },
  { id: 'date', name: 'Input de Data', element: Date },
];

export { sectionChoices, productStructChoices };
