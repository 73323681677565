import React from 'react';
import { useRecordContext } from 'react-admin';

const ShortField = ({ source, transform }) => {
  const record = useRecordContext();
  const regex = /(&nbsp;|<([^>]+)>)/gi;
  let text = record[source]?.replace(regex, ' ');

  if (transform) {
    text = transform(text);
  }

  if (text && text.length >= 50) {
    text = `${text.substring(0, 50)}...`;
  }

  return record ? <span>{text}</span> : null;
};

export default ShortField;
