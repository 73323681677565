import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { AutocompleteInput, useGetList } from 'react-admin';

import AutorRapido from './AutorRapido';

const AutorNew = () => {
  const { data, isLoading } = useGetList('blog_authors', {
    target: 'author',
    company: localStorage.getItem('company'),
    pagination: { page: 1, perPage: 100 },
  });

  const handleChange = (...res) => {
    data.push(...res);
  };

  if (isLoading) return <CircularProgress />;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '50px',
        width: '100%',
      }}
    >
      <AutocompleteInput
        fullWidth
        label="Autor"
        source="author"
        choices={data}
        style={{ marginRight: '5px' }}
        optionText={(choice) => `${choice.name}`}
      />

      <AutorRapido onChange={handleChange} />
    </div>
  );
};

export default AutorNew;
