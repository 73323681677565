/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useGetList } from 'react-admin';
import { useCookies } from 'react-cookie';

import InstagramIcon from '../../assets/instagram_icon.webp';

import './style.css';

const Instagram = ({ isLoading, data }) => {
  const [text, setText] = useState('');
  const [disabled, setDisabled] = useState(false);

  if (isLoading) return <CircularProgress />;
  if (!data || !data[0].should_show_instagram) return null;

  const { instagram_access_token, instagramUsername } = data[0];
  if (instagram_access_token) {
    const token = localStorage.getItem('token');
    const company = localStorage.getItem('company');
    const config = {
      headers: {
        company,
        authorization: `Bearer ${token}`,
      },
    };

    return (
      <div
        style={{
          marginTop: '8px',
          marginBottom: '8px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Button
          style={{ marginBottom: '8px' }}
          variant="outlined"
          startIcon={<img src={InstagramIcon} alt="Instagram" height="20px" />}
          disabled={disabled}
          onClick={async () => {
            if (
              confirm(
                'Tem certeza que deseja desconectar o Instagram? Essa ação não pode ser desfeita',
              )
            ) {
              setText('Carregando...');
              setDisabled(true);

              await axios
                .post(
                  `${process.env.REACT_APP_API_URL}/instagram/revoke`,
                  {
                    access_token: data[0].instagram_access_token,
                  },
                  config,
                )
                .then(() => {
                  window.open(
                    'https://www.instagram.com/accounts/manage_access/',
                    '_blank',
                    'noopener,noreferrer',
                  );
                  setDisabled(false);
                  setText('');
                });
            }
          }}
        >
          {text || 'Desconectar o Instagram'}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            window.open(
              `https://www.instagram.com/${instagramUsername}`,
              '_blank',
              'noopener,noreferrer',
            );
          }}
        >
          Visualizar conta conectada
        </Button>
      </div>
    );
  }

  return (
    <div
      style={{
        marginTop: '8px',
        marginBottom: '8px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button
        variant="outlined"
        startIcon={<img src={InstagramIcon} alt="Instagram" height="20px" />}
        onClick={() => {
          const appId = process.env.REACT_APP_INSTAGRAM_APP_ID;
          const redUri = encodeURI(`${window.location.origin}/insta/`);
          const url = `https://api.instagram.com/oauth/authorize?client_id=${appId}&redirect_uri=${redUri}&scope=user_profile,user_media&response_type=code`;
          window.open(url, '_blank', 'noopener,noreferrer');
        }}
      >
        Conectar ao Instagram
      </Button>
    </div>
  );
};

const Dashboard = () => {
  const [cookies] = useCookies([]);
  let title = '';

  if (cookies.company) {
    title = `Bem-vindo(a) à Área de Administração da ${cookies.company}`;
  }

  const { data, isLoading } = useGetList('companies', {
    target: 'companies',
    company: localStorage.getItem('company'),
    pagination: { page: 1, perPage: 100 },
    filter: {
      id: localStorage.getItem('company'),
      onlyInsta: true,
    },
  });

  return (
    <div
      className="DashboardContent"
      style={{ flexWrap: 'wrap', justifyContent: 'center' }}
    >
      {data && data[0].should_show_instagram && (
        <Card>
          <CardContent>
            <CardHeader title="Gerenciar o Instagram" />
            <Instagram isLoading={isLoading} data={data} />
          </CardContent>
        </Card>
      )}
      <Card>
        <CardHeader title={title} />
        <CardContent>
          Aqui você vai encontrar uma visão geral de como a sua empresa está!
        </CardContent>
      </Card>
    </div>
  );
};

export default Dashboard;
