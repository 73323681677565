import React from 'react';
import { DateInput, required, useRecordContext } from 'react-admin';

const Datas = (props) => {
  const { timeNow } = props;
  const record = useRecordContext();

  return (
    <div>
      <DateInput
        validate={required()}
        label="Data Inicial"
        source="start_date"
        style={{ marginRight: '10px' }}
        value={(timeNow || record.start_date) || ''}
        defaultValue={(timeNow || record.start_date) || ''}
        format={(data) => data && new Date(data).toISOString().split('T')[0]}
      />
      <DateInput
        label="Data Final"
        source="end_date"
        value={(record && record.end_date) || ''}
        defaultValue={(record && record.end_date) || ''}
        format={(data) => data && new Date(data).toISOString().split('T')[0]}
      />
    </div>
  );
};

export default Datas;
