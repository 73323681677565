import CloseIcon from '@mui/icons-material/Close';
import { format, isValid } from 'date-fns';
import {
  RichTextInput,
  RichTextInputToolbar,
  FormatButtons,
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
} from 'ra-input-rich-text';
import React, { useMemo } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  FileInput,
  ImageInput,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  BooleanInput,
  useRecordContext,
  DateInput,
  DateField,
  usePermissions,
  ImageField,
  useNotify,
  ListButton,
  RadioButtonGroupInput,
} from 'react-admin';
import { useCookies } from 'react-cookie';

import Datas from '../../../components/dates';
import FilePreview from '../../../components/FilePreview';
import ImagePreview from '../../../components/ImagePreviewWithShowFirst';
import ShortField from '../../../components/ShortField';
import { FILE_MAX_ZIZE, IMAGE_MAX_SIZE } from '../../../helpers/constants';
import AutorNew from './AutorNew';
import CategoriaNew from './CategoriaNew';
import { EditorBlog } from './EditorConteudo';
import ToggleBlog from './ToggleBlog';

// import TagInput from './TagInput';

const PostsTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  const startDate = new Date(
    record.data ? record.data.start_date : record.start_date,
  );
  const endDate = new Date(
    record.data ? record.data.end_date : record.end_date,
  );
  const start = new Date(startDate);
  const end = new Date(endDate);
  const userTimezoneOffset = start.getTimezoneOffset() * 60000;
  start.setTime(start.getTime() + userTimezoneOffset);
  end.setTime(end.getTime() + userTimezoneOffset);

  const texto = isValid(record.end_date)
    ? `até ${format(end, 'd/M/yyyy')}`
    : '';
  return (
    <span>Post {record ? `de ${format(start, 'd/M/yyyy')} ${texto}` : ''}</span>
  );
};

const PostsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
    <ReferenceInput
      label="Categoria"
      source="category"
      reference="blog_categories"
      allowEmpty
    >
      <SelectInput optionText="name" label="Categoria" />
    </ReferenceInput>
    <ReferenceInput
      label="Autor"
      source="author"
      reference="blog_authors"
      allowEmpty
    >
      <SelectInput optionText="name" label="Autor" />
    </ReferenceInput>
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_BLOG_POSTS_DELETE')) && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

export const Posts = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();

  return (
    <List
      perPage={25}
      title="Posts do blog"
      filters={<PostsFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
    >
      <Datagrid
        bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      >
        <ToggleBlog label="Ativo" source="disabled" {...props} />
        <ImageField
          sortable={false}
          source="image"
          label="Imagem"
          title="Imagem"
        />
        <TextField label="Título" source="title" />
        <DateField
          label="Data de Publicação"
          source="start_date"
          options={{ timeZone: 'UTC' }}
        />
        <DateField
          label="Data de Remoção"
          source="end_date"
          options={{ timeZone: 'UTC' }}
        />
        {!permissions.isLoading &&
          (permissions.permissions.includes('ROLE_BLOG_CATEGORIAS_LIST') ||
            permissions.permissions.includes('SYS_ADMIN')) && (
            <ReferenceField
              label="Categoria"
              source="category"
              reference="blog_categories"
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
          )}
        {!permissions.isLoading &&
          (permissions.permissions.includes('ROLE_BLOG_AUTORES_LIST') ||
            permissions.permissions.includes('SYS_ADMIN')) && (
            <ReferenceField
              label="Autor"
              source="author"
              reference="blog_authors"
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
          )}
        <ShortField label="Linguagem" source="language" transform={txt => {
          switch (txt) {
            case 'portuguese':
              return 'Português';
            case 'english':
              return 'Inglês';
            case 'spanish':
              return 'Espanhol';
            default:
              return 'Indefinido';
          }
        }} />
        {!permissions.isLoading &&
          (permissions.permissions.includes('SYS_ADMIN') ||
            permissions.permissions.includes('ROLE_BLOG_POSTS_UPDATE')) && (
            <EditButton />
          )}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    className="botoesUpdate"
    style={{ justifyContent: 'space-between' }}
  >
    <div
      style={{
        width: '250px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton alwaysEnable />
      <ListButton
        size="medium"
        variant="outlined"
        label="cancelar"
        icon={<CloseIcon />}
      />
    </div>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_BLOG_POSTS_DELETE')) && (
        <DeleteButton size="medium" />
      )}
  </Toolbar>
);

const CategoriaAutor = ({ permissions }) => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
    }}
  >
    {!permissions.isLoading &&
      (permissions.permissions.includes('ROLE_BLOG_CATEGORIAS_LIST') ||
        permissions.permissions.includes('SYS_ADMIN')) && (
        <CategoriaNew permissions={permissions} />
      )}
    {!permissions.isLoading &&
      (permissions.permissions.includes('ROLE_BLOG_AUTORES_LIST') ||
        permissions.permissions.includes('SYS_ADMIN')) && (
        <AutorNew permissions={permissions} />
      )}
  </div>
);

const IfIdCompanyOnArray = ({ array, ifElement, elseElement }) => {
  const id = localStorage.getItem('company');

  if (!id) return <></>;
  if ((id) && array.includes(Number(id))) {
    return ifElement;
  } else return elseElement;
};

export const PostsEdit = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const { source, ...rest } = props;

  return (
    <Edit redirect="list" title={<PostsTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <RadioButtonGroupInput source="language" label="Idioma" choices={[
          { id: 'portuguese', name: 'Português' },
          { id: 'english', name: 'Inglês' },
          { id: 'spanish', name: 'Espanhol' },
        ]} />
        <CategoriaAutor permissions={permissions} />
        <TextInput
          label="Título"
          validate={required()}
          source="title"
          fullWidth
        />
        <IfIdCompanyOnArray
          array={[7, 10]}
          ifElement={<EditorBlog source="description" {...rest} />}
          elseElement={
            <RichTextInput
              toolbar={
                <RichTextInputToolbar>
                  <LevelSelect />
                  <AlignmentButtons />
                  <FormatButtons />
                  <ListButtons />
                  <LinkButtons />
                  <QuoteButtons />
                  <ClearButtons />
                </RichTextInputToolbar>
              }
              validate={required()}
              label="Descrição"
              source="description"
              fullWidth
            />
          }
        />
        <Datas />
        <TextInput
          label="Tags separadas por vírgula, ex: tag1, tag2, tag3"
          source="tags"
          fullWidth
        />
        <ImageInput
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 35MB', { type: 'warning' });
              }
            },
          }}
          multiple
          label="Fotos do Post (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImagePreview {...props} />
        </ImageInput>
        <FileInput
          maxSize={FILE_MAX_ZIZE}
          source="docs"
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 35MB', { type: 'warning' });
              }
            },
          }}
          label="Arquivos do Post Blog (pdf, doc, docx, xls, xlsx, ppt, pptx, odt, ods, odp)"
          multiple
          accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.presentation"
        >
          <FilePreview {...props} />
        </FileInput>
        <BooleanInput label="Desabilitar Post?" source="disabled" />
        <div>
          <DateInput
            style={{ marginRight: '10px' }}
            label="Data de Criação"
            source="createdAt"
            disabled
          />
          <DateInput label="Data de Edição" source="updatedAt" disabled />
        </div>
      </SimpleForm>
    </Edit>
  );
};

export const PostsCreate = (props) => {
  const [cookies] = useCookies([]);
  const agora = new Date();
  agora.setHours(0, 0, 0, 0);
  const userTimezoneOffset = agora.getTimezoneOffset() * 60000;
  agora.setTime(agora.getTime() - userTimezoneOffset);
  const permissions = usePermissions();
  const notify = useNotify();
  const { source, ...rest } = props;

  const languages = useMemo(() => {
    const list = [
      { id: 'portuguese', name: 'Português' },
    ];
    if (cookies.english === '1') {
      list.push({ id: 'english', name: 'Inglês' });
    }
    if (cookies.spanish === '1') {
      list.push({ id: 'spanish', name: 'Espanhol' });
    }
    return list;
  }, [cookies.spanish, cookies.english]);

  return (
    <Create redirect="list" {...props} title="Novo Post">
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <RadioButtonGroupInput defaultValue={'portuguese'} source="language" label="Idioma" choices={languages} />
        <CategoriaAutor permissions={permissions} />
        <TextInput
          label="Título"
          validate={required()}
          source="title"
          fullWidth
        />
        <IfIdCompanyOnArray
          array={[7, 10]}
          ifElement={<EditorBlog source="description" {...rest} />}
          elseElement={
            <RichTextInput
              toolbar={
                <RichTextInputToolbar>
                  <LevelSelect />
                  <AlignmentButtons />
                  <FormatButtons />
                  <ListButtons />
                  <LinkButtons />
                  <QuoteButtons />
                  <ClearButtons />
                </RichTextInputToolbar>
              }
              validate={required()}
              label="Descrição"
              source="description"
              fullWidth
            />
          }
        />
        <Datas timeNow={agora} />
        <TextInput
          label="Tags separadas por vírgula, ex: tag1, tag2, tag3"
          source="tags"
          fullWidth
        />
        <ImageInput
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 35MB', { type: 'warning' });
              }
            },
          }}
          multiple
          label="Fotos do Post (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImagePreview {...props} />
        </ImageInput>
        <FileInput
          maxSize={FILE_MAX_ZIZE}
          source="docs"
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 35MB', { type: 'warning' });
              }
            },
          }}
          label="Arquivos do Post Blog (pdf, doc, docx, xls, xlsx, ppt, pptx, odt, ods, odp)"
          multiple
          accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.presentation"
        >
          <FilePreview {...props} />
        </FileInput>
        <BooleanInput label="Desabilitar Post?" source="disabled" />
      </SimpleForm>
    </Create>
  );
};

export default Posts;
