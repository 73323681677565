/* eslint-disable import/no-webpack-loader-syntax */
// eslint-disable-next-line import/no-extraneous-dependencies
import logo from '!file-loader!../../../assets/logo.svg';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import DefaultIcon from '@mui/icons-material/ViewList';
import { Divider, Tooltip, Link } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import {
  MenuItemLink,
  usePermissions,
  useSidebarState,
  useResourceDefinitions,
  Logout,
} from 'react-admin';

import './style.css';
import logoSemEscrita from '../../../assets/favicon.png';

const Menu = ({ onMenuClick }) => {
  const { permissions } = usePermissions();
  const [open] = useSidebarState();
  const [openEmpresa, setOpenEmpresa] = React.useState(false);
  const [openSecao, setOpenSecao] = React.useState(false);
  const [openBlog, setOpenBlog] = React.useState(false);
  const [openProdServ, setOpenProdServ] = React.useState(false);
  const [openNewsletter, setOpenNewsletter] = React.useState(false);

  const handleClickEmpresa = () => {
    setOpenEmpresa(!openEmpresa);
  };

  const handleClickSecao = () => {
    setOpenSecao(!openSecao);
  };

  const handleClickBlog = () => {
    setOpenBlog(!openBlog);
  };

  const handleClickProdServ = () => {
    setOpenProdServ(!openProdServ);
  };

  const handleClickNewsletter = () => {
    setOpenNewsletter(!openNewsletter);
  };

  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(
    (name) => resourcesDefinitions[name],
  );

  let naoMostrar = ['permissions', 'sections', 'company_sections', 'companies'];
  if (permissions && permissions.includes('SYS_ADMIN')) {
    naoMostrar = [];
  } else if (permissions && permissions.includes('ROLE_EMPRESAS_LIST')) {
    naoMostrar.pop();
  }

  const empresa = [
    'companies',
    'company_praises',
    'company_information',
    'social_media',
    'menus',
    'banners',
    'users',
    'intern_banners',
  ];
  const secao = ['sections', 'company_sections', 'section_informations'];
  const blog = ['blog_categories', 'blog_authors', 'blog_posts'];
  const productService = [
    'products_services',
    'product_section_structure',
    'categories',
    'brands',
    'characteristics',
    'characteristics_descriptions',
  ];
  const newsletter = ['newsletter', 'newsletter_emails'];

  const empresaRes = [];
  const newsletterRes = [];
  const blogRes = [];
  const productServiceRes = [];
  const secaoRes = [];

  resources.forEach((resource) => {
    if (resource.options || resource.icon) {
      if (empresa.includes(resource.name)) {
        empresaRes.push(resource);
      } else if (secao.includes(resource.name)) {
        secaoRes.push(resource);
      } else if (blog.includes(resource.name)) {
        blogRes.push(resource);
      } else if (productService.includes(resource.name)) {
        productServiceRes.push(resource);
      } else if (newsletter.includes(resource.name)) {
        newsletterRes.push(resource);
      }
    }
  });

  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <MenuItemLink
        to="/"
        primaryText="Início"
        leftIcon={<HomeIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <Divider />

      {/* Empresas */}
      {empresaRes.length > 0 && (
        <>
          <ListItemButton onClick={handleClickEmpresa}>
            <ListItemIcon>
              <StoreOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Empresa" />
            {openEmpresa ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openEmpresa} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {empresaRes.map((resource) => {
                if (!naoMostrar.includes(resource.name)) {
                  return (
                    <MenuItemLink
                      key={resource.name}
                      to={`/${resource.name}`}
                      primaryText={
                        (resource.options && resource.options.label) ||
                        resource.name
                      }
                      leftIcon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                      }
                      onClick={onMenuClick}
                      sidebarIsOpen={open}
                      sx={open ? { pl: 4 } : { pl: 2 }}
                    />
                  );
                }
                return null;
              })}
            </List>
          </Collapse>
        </>
      )}

      {/* Newsletters */}
      {newsletterRes.length > 0 && (
        <>
          <ListItemButton onClick={handleClickNewsletter}>
            <ListItemIcon>
              <EmailOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Newsletter" />
            {openNewsletter ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openNewsletter} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {newsletterRes.map((resource) => {
                if (!naoMostrar.includes(resource.name)) {
                  return (
                    <MenuItemLink
                      key={resource.name}
                      to={`/${resource.name}`}
                      primaryText={
                        (resource.options && resource.options.label) ||
                        resource.name
                      }
                      leftIcon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                      }
                      onClick={onMenuClick}
                      sidebarIsOpen={open}
                      sx={open ? { pl: 4 } : { pl: 2 }}
                    />
                  );
                }
                return null;
              })}
            </List>
          </Collapse>
        </>
      )}

      {/* Blog */}
      {blogRes.length > 0 && (
        <>
          <ListItemButton onClick={handleClickBlog}>
            <ListItemIcon>
              <AssignmentOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Blog" />
            {openBlog ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openBlog} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {blogRes.map((resource) => {
                if (!naoMostrar.includes(resource.name)) {
                  return (
                    <MenuItemLink
                      key={resource.name}
                      to={`/${resource.name}`}
                      primaryText={
                        (resource.options && resource.options.label) ||
                        resource.name
                      }
                      leftIcon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                      }
                      onClick={onMenuClick}
                      sidebarIsOpen={open}
                      sx={open ? { pl: 4 } : { pl: 2 }}
                    />
                  );
                }
                return null;
              })}
            </List>
          </Collapse>
        </>
      )}

      {/* Produto/Serviço */}
      {productServiceRes.length > 0 && (
        <>
          <ListItemButton onClick={handleClickProdServ}>
            <ListItemIcon>
              <Inventory2OutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Produto/Serviço" />
            {openProdServ ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openProdServ} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {productServiceRes.map((resource) => {
                if (!naoMostrar.includes(resource.name)) {
                  return (
                    <MenuItemLink
                      key={resource.name}
                      to={`/${resource.name}`}
                      primaryText={
                        (resource.options && resource.options.label) ||
                        resource.name
                      }
                      leftIcon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                      }
                      onClick={onMenuClick}
                      sidebarIsOpen={open}
                      sx={
                        open
                          ? {
                              pl:
                                resource.name === 'characteristics_descriptions'
                                  ? 6
                                  : 4,
                            }
                          : { pl: 2 }
                      }
                    />
                  );
                }
                return null;
              })}
            </List>
          </Collapse>
        </>
      )}

      {/* Seção */}
      {secaoRes.length > 0 && (
        <>
          <ListItemButton onClick={handleClickSecao}>
            <ListItemIcon>
              <ListAltOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Seção" />
            {openSecao ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openSecao} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {secaoRes.map((resource) => {
                if (!naoMostrar.includes(resource.name)) {
                  return (
                    <MenuItemLink
                      key={resource.name}
                      to={`/${resource.name}`}
                      primaryText={
                        (resource.options && resource.options.label) ||
                        resource.name
                      }
                      leftIcon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                      }
                      onClick={onMenuClick}
                      sidebarIsOpen={open}
                      sx={open ? { pl: 4 } : { pl: 2 }}
                    />
                  );
                }
                return null;
              })}
            </List>
          </Collapse>
        </>
      )}
      {/* Restante */}
      {resources.map((resource) => {
        if (
          !naoMostrar.includes(resource.name) &&
          ![
            ...empresa,
            ...secao,
            ...blog,
            ...productService,
            ...newsletter,
          ].includes(resource.name)
        ) {
          return (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && resource.options.label) || resource.name
              }
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          );
        }
        return null;
      })}
      <Divider />
      <Tooltip title="Sair" placement="right">
        <Logout />
      </Tooltip>
      <Divider />
      <div
        style={{
          padding: '5px',
          textAlign: 'center',
          marginTop: '25px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: `${open ? 'row' : 'column'}`,
        }}
      >
        <Link
          color="inherit"
          target="_blank"
          href="https://tytotech.com.br/"
          style={{
            textDecoration: 'none',
            height: '24px',
            marginRight: '5px',
          }}
          title="Desenvolvido por Tytotech"
        >
          <img
            src={open ? logo : logoSemEscrita}
            alt="Tytotech"
            height="100%"
          />
        </Link>
        <span> v{process.env.REACT_APP_VERSION}</span>
      </div>
    </List>
  );
};

export default Menu;
