// eslint-disable-next-line import/order, import/no-unresolved, import/no-webpack-loader-syntax
import background from '!file-loader!../../assets/images/background-login.svg';
import { ArrowBack } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  CssBaseline,
} from '@mui/material';
import { validate } from 'email-validator';
import React, { useState } from 'react';
import {
  useNotify,
  Notification,
  useRedirect,
  useAuthProvider,
} from 'react-admin';
import { Link } from 'react-router-dom';

import Copyright from '../../components/Copyright';

export default function EsqueciSenha() {
  const [email, setEmail] = useState('');
  const notify = useNotify();
  const redirect = useRedirect();
  const authProvider = useAuthProvider();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      if (validate(email)) {
        authProvider.resetarSenha({ email }).then(() => {
          notify('Um email de confirmação foi enviado!');

          redirect('/login');
        });
      } else {
        notify('E-mail inválido', 'warning');
      }
    } else {
      notify('Preencha o E-mail', 'warning');
    }
  };

  return (
    <Grid container component="main" style={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'gray',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div>
          <Avatar style={{ margin: '10px' }}>
            <Link
              to="/login"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <ArrowBack />
            </Link>
          </Avatar>
        </div>
        <div
          style={{
            margin: '64px 32px 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Esqueci minha Senha
          </Typography>
          <form
            style={{
              width: '100%',
              marginTop: '8px',
            }}
            onSubmit={handleSubmit}
            noValidate
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ margin: '24px 0px 16px' }}
            >
              Resetar Senha
            </Button>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
          <Notification />
        </div>
      </Grid>
    </Grid>
  );
}
