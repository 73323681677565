/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import {
  Link,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import { AppBar, useDataProvider, usePermissions } from 'react-admin';
import { useCookies } from 'react-cookie';

const PREFIX = 'MyAppBar';

const classes = {
  title: `${PREFIX}-title`,
  spacer: `${PREFIX}-spacer`,
  aligncontentcenter: `${PREFIX}-aligncontentcenter`,
  marginRight: `${PREFIX}-marginRight`,
};

const StyledAppBar = styled(AppBar)({
  [`& .${classes.title}`]: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  [`& .${classes.spacer}`]: {
    flex: 1,
  },
  [`& .${classes.aligncontentcenter}`]: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  [`& .${classes.marginRight}`]: {
    marginRight: '10px',
  },
});

const MyAppBar = (props) => {
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [cookies, setCookie] = useCookies([]);
  const [companies, setcompanies] = useState([]);
  const { isLoading, permissions } = usePermissions();
  const [atual, setAtual] = useState(cookies.company);

  let showAdmin = '';

  if (!isLoading && permissions.includes('SYS_ADMIN')) {
    showAdmin = 'ADMIN: ';
  }

  useEffect(() => {
    if (localStorage.getItem('companies')) {
      dataProvider
        .getMany('user_companies', { ids: localStorage.getItem('companies') })
        .then(({ data }) => {
          setcompanies(data);
          if (data.length > 1) {
            if (!cookies.company) {
              setOpen(true);
            }
            setAtual(cookies.company ? cookies.company : '');
          }
          return true;
        });
    }
  }, []);

  const handleChange = (event, novo) => {
    const companyName = `${novo.props.value.name} - ${novo.props.value.state}`;
    setCookie('company', companyName, { path: '/' });
    setCookie('spanish', novo.props.value.spanish ? 1 : 0, { path: '/' });
    setCookie('english', novo.props.value.english ? 1 : 0, { path: '/' });
    localStorage.setItem('company', novo.props.value.id);
    // Melhorar isso
    window.location.reload();
  };

  return (
    (<StyledAppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
      <div className={classes.aligncontentcenter}>
        {showAdmin && (
          <Typography
            className={classes.marginRight}
            variant="h6"
            color="inherit"
            id="adminNavbar"
            style={{ marginRight: '12px' }}
          >
            {showAdmin}
          </Typography>
        )}
        <Typography
          className={classes.marginRight}
          variant="h6"
          color="inherit"
          id="companyNavbar"
        >
          {atual}
        </Typography>
        {companies.length > 1 && (
          <Tooltip title="Trocar de Empresa">
            <Link
              style={{ padding: '12px', cursor: 'pointer' }}
              className={classes.aligncontentcenter}
              onClick={() => setOpen(true)}
              color="inherit"
            >
              <CompareArrowsIcon />
            </Link>
          </Tooltip>
        )}
      </div>
      <Modal open={open} className="modalSelectcompany">
        <div className="modalContainer">
          <InputLabel id="company">Selecione sua Empresa</InputLabel>
          <Select
            labelId="company"
            id="selectcompany"
            onChange={handleChange}
            style={{ width: '100%' }}
            value=""
            variant="standard"
          >
            {companies.map((company) => (
              <MenuItem
                key={company.id}
                name={company.id}
                value={company}
              >
                {company.name} - {company.state}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Modal>
    </StyledAppBar>)
  );
};

export default MyAppBar;
