import CloseIcon from '@mui/icons-material/Close';
import { Typography, Button, Slider, CircularProgress } from '@mui/material';
import {
  RichTextInput,
  RichTextInputToolbar,
  FormatButtons,
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
} from 'ra-input-rich-text';
import React, { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ImageInput,
  ImageField,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  email,
  FormDataConsumer,
  usePermissions,
  useRecordContext,
  useNotify,
  ListButton,
  useGetList,
  CheckboxGroupInput,
} from 'react-admin';
import { useWatch } from 'react-hook-form';

import { IMAGE_MAX_SIZE } from '../../helpers/constants';
import {
  MascaraCelular,
  MascaraCNPJ,
  MascaraTelefone,
  validateCelPhoneFixOr0800Number,
} from '../../helpers/masks';
import CEPAuto from './CEPAuto';
import ColorPicker from './ColorPicker';

const EmpresasTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return <span>Empresa {record ? `"${record.name}"` : ''}</span>;
};

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {!permissions.isLoading &&
      permissions.permissions.includes('SYS_ADMIN') &&
      props.selectedIds.indexOf(
        parseInt(localStorage.getItem('company'), 10),
      ) === -1 && <BulkDeleteButton {...props} />}
  </>
);

export const Empresas = (props) => {
  const permissions = usePermissions();

  return (
    <List perPage={25} title="Empresas" {...props}>
      <Datagrid
        bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      >
        <ImageField
          sortable={false}
          source="image"
          title="Imagem"
          label="Ícone"
        />
        <TextField label="Estado" source="state" />
        <TextField label="Cidade" source="city" />
        <TextField label="Nome" source="name" />
        {!permissions.isLoading &&
          (permissions.permissions.includes('SYS_ADMIN') ||
            permissions.permissions.includes('ROLE_EMPRESAS_UPDATE')) && (
            <EditButton />
          )}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => {
  const record = useRecordContext();

  return (
    <Toolbar
      {...props}
      className="botoesUpdate"
      style={{ justifyContent: 'space-between' }}
    >
      <div
        style={{
          width: '250px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <SaveButton alwaysEnable />
        <ListButton
          size="medium"
          variant="outlined"
          label="cancelar"
          icon={<CloseIcon />}
        />
      </div>
      {permissions &&
        permissions.permissions &&
        permissions.permissions.includes('SYS_ADMIN') &&
        record &&
        record.id !== parseInt(localStorage.getItem('company'), 10) && (
          <DeleteButton size="medium" />
        )}
    </Toolbar>
  );
};

const WebsiteLink = () => {
  const permissions = usePermissions();

  if (!permissions.isLoading && permissions.permissions.includes('SYS_ADMIN')) {
    return (
      <>
        <BooleanInput
          label="Redimensionar novas imagens de Produtos/Serviços?"
          source="should_resize_product_service"
        />
        <BooleanInput
          label="Diferenciar entre Produto e Serviço?"
          source="should_differentiate_service"
        />
        <BooleanInput
          label="Produto/Serviço tem Característica?"
          source="prod_serv_has_characteristics"
        />
        <BooleanInput
          label="Mostrar botão de conectar Instagram?"
          source="should_show_instagram"
        />
        <TextInput
          fullWidth
          validate={required()}
          label="Website da Empresa"
          source="website_link"
        />
      </>
    );
  }
  return null;
};

const RedimensionarProdutoServico = () => {
  const shouldResize = useWatch({ name: 'should_resize_product_service' });

  return (
    <>
      {shouldResize && (
        <div style={{ display: 'flex' }}>
          <TextInput
            type="number"
            validate={required()}
            label="Largura"
            source="product_service_width"
            style={{ marginRight: '10px' }}
            fullWidth
          />
          <TextInput
            type="number"
            validate={required()}
            label="Altura"
            source="product_service_height"
            fullWidth
          />
        </div>
      )}
    </>
  );
};

const Captchas = () => {
  const permissions = usePermissions();

  if (!permissions.isLoading && permissions.permissions.includes('SYS_ADMIN')) {
    return (
      <>
        <TextInput
          fullWidth
          validate={required()}
          label="Chave do Site do Captcha"
          source="captcha_site_key"
        />
        <TextInput
          fullWidth
          validate={required()}
          label="Chave do Segredo do Captcha"
          source="captcha_secret_key"
        />
      </>
    );
  }
  return null;
};

const Analytics = () => {
  const permissions = usePermissions();

  if (!permissions.isLoading) {
    return (
      <TextInput
        fullWidth
        validate={required()}
        label="ID do Analytics"
        source="ga_tracking_id"
      />
    );
  }
  return null;
};

const Infos = () => (
  <div>
    <TextInput
      validate={required()}
      label="Nome"
      source="name"
      style={{ marginRight: '10px' }}
    />
    <TextInput
      validate={[required(), email()]}
      label="E-mail"
      source="email"
      style={{ marginRight: '10px' }}
    />
    <TextInput
      validate={[email()]}
      label="E-mail secundário"
      source="email2"
      style={{ marginRight: '10px' }}
    />
    <TextInput
      label="WhatsApp"
      // InputProps={{
      //   inputComponent: MascaraCelular,
      // }}
      validate={validateCelPhoneFixOr0800Number}
      source="whatsapp"
      style={{ marginRight: '10px' }}
    />
    <TextInput
      label="CNPJ"
      InputProps={{
        inputComponent: MascaraCNPJ,
      }}
      source="cnpj"
      style={{ marginRight: '10px' }}
    />
  </div>
);

const Cores = () => (
  <div style={{ width: '100%', display: 'flex' }}>
    <div style={{ width: '100%' }}>
      <ColorPicker source="color1" label="Cor Primária" />
      <ColorPicker source="color2" label="Cor Secundária" />
      <ColorPicker source="color3" label="Cor Terciária" />
    </div>
    <div style={{ marginRight: '10%', width: '100%' }}>
      <FormDataConsumer>
        {({ formData }) => (
          <>
            <div
              key={formData}
              style={{
                backgroundColor: formData.color3,
                display: 'flex',
              }}
            >
              <Typography
                style={{
                  color: formData.color1,
                  marginLeft: 50,
                  cursor: 'pointer',
                }}
                variant="h6"
              >
                Links (cor primária)
              </Typography>
            </div>
            <Button
              variant="outlined"
              style={{
                color: formData.color2,
                marginTop: '14px',
                marginLeft: '5px',
              }}
            >
              Cor Secundária
            </Button>
            <Slider
              defaultValue={0}
              style={{
                color: formData.color2,
              }}
              valueLabelDisplay="auto"
            />
          </>
        )}
      </FormDataConsumer>
    </div>
  </div>
);

const EnabledLanguages = () => {
  const record = useRecordContext();
  if (!record) return null;

  const choices = [
    record.hasEnglish ? { id: 'english', name: 'Inglês' } : null,
    record.hasSpanish ? { id: 'spanish', name: 'Espanhol' } : null,
  ].filter((data => !!data));

  return (record.hasEnglish || record.hasSpanish) ? <CheckboxGroupInput source="languages" label="Línguas estrangeiras habilitadas" choices={choices} /> : <></>;
};

export const EmpresasEdit = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Edit redirect="list" title={<EmpresasTitle />} {...props}>
          <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
          <EnabledLanguages />
          <ImageInput
            validate={required()}
            source="pictures"
            maxSize={IMAGE_MAX_SIZE}
            options={{
              onDropRejected: (res) => {
                if (res[0].errors[0].code === 'file-too-large') {
                  notify('Arquivo excede 35MB', { type: 'warning' });
                }
              },
            }}
            label="Logotipo Menu (jpeg, png, jpg, gif, svg, webp, bmp)"
            accept="image/*"
          >
            <ImageField source="src" title="Ícone" />
          </ImageInput>
          <ImageInput
            source="watermark"
            label="Logotipo Rodapé (jpeg, png, jpg, gif, svg, webp, bmp)"
            accept="image/*"
            maxSize={IMAGE_MAX_SIZE}
            options={{
              onDropRejected: (res) => {
                if (res[0].errors[0].code === 'file-too-large') {
                  notify('Arquivo excede 35MB', { type: 'warning' });
                }
              },
            }}
          >
            <ImageField source="src" title="Marca D'Água" />
          </ImageInput>
          <Infos />
          <TextInput
            validate={required()}
            label="Link do Mapa"
            source="linkMaps"
            fullWidth
          />
          <CEPAuto />
          <ArrayInput source="phones" validate={required()} label="Telefone(s)">
            <SimpleFormIterator>
              <TextInput
                key="phone"
                label="Número"
                // InputProps={{
                //   inputComponent: MascaraTelefoneCelularFixo0800,
                // }}
                validate={[required(), validateCelPhoneFixOr0800Number]}
                source="phone"
              />
            </SimpleFormIterator>
          </ArrayInput>
          <RichTextInput
            toolbar={
              <RichTextInputToolbar>
                <LevelSelect />
                <AlignmentButtons />
                <FormatButtons />
                <ColorButtons />
                <ListButtons />
                <LinkButtons />
                <QuoteButtons />
                <ClearButtons />
              </RichTextInputToolbar>
            }
            fullWidth
            label="Horário de atendimento"
            source="working_hours"
          />
          <Cores />
          <BooleanInput label="Desabilitar Empresa?" source="disabled" />
          <BooleanInput
            label="Mostrar botão tenho interesse em Produtos/Serviços?"
            source="message_link_product_service"
          />
          <WebsiteLink {...props} />
          <RedimensionarProdutoServico />
          <Captchas {...props} />
          <Analytics {...props} />
        </SimpleForm>
    </Edit>
  );
};

export const EmpresasCreate = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Create redirect="list" {...props} title="Nova Empresa">
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <CheckboxGroupInput source="hasLanguages" label="Línguas estrangeiras permitidas" choices={[
          { id: 'english', name: 'Inglês' },
          { id: 'spanish', name: 'Espanhol' },
        ]} />
        <ImageInput
          validate={required()}
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 35MB', { type: 'warning' });
              }
            },
          }}
          label="Logotipo Menu (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImageField source="src" title="Ícone" />
        </ImageInput>
        <ImageInput
          source="watermark"
          label="Logotipo Rodapé (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 35MB', { type: 'warning' });
              }
            },
          }}
        >
          <ImageField source="src" title="Marca D'Água" />
        </ImageInput>
        <Infos />
        <TextInput
          validate={required()}
          label="Link do Mapa"
          fullWidth
          source="linkMaps"
        />
        <CEPAuto />
        <ArrayInput source="phones" validate={required()} label="Telefone(s)">
          <SimpleFormIterator>
            <TextInput
              key="phone"
              label="Número"
              validate={[required(), validateCelPhoneFixOr0800Number]}
              source="phone"
            />
          </SimpleFormIterator>
        </ArrayInput>
        <RichTextInput
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <AlignmentButtons />
              <FormatButtons />
              <ColorButtons />
              <ListButtons />
              <LinkButtons />
              <QuoteButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          fullWidth
          label="Horário de atendimento"
          source="working_hours"
        />
        <Cores />
        <BooleanInput
          label="Mostrar botão tenho interesse em Produtos/Serviços?"
          source="message_link_product_service"
        />
        <WebsiteLink {...props} />
        <RedimensionarProdutoServico />
        <Captchas {...props} />
        <Analytics {...props} />
      </SimpleForm>
    </Create>
  );
};

export default Empresas;
