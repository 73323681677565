import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { AutocompleteInput, useGetList, required } from 'react-admin';

import DescricaoCaracteristicaRapida from './DescricaoCaracteristicaRapida';

const DescricaoCaracteristicaNew = ({ source, characteristic }) => {
  const { data: dataCharacteristic, isLoading: isLoadingCharacteristic } =
    useGetList('characteristics', {
      target: 'characteristic',
      company: localStorage.getItem('company'),
      pagination: { page: 1, perPage: 100 },
      filter: { characteristic },
    });

  const { data, isLoading } = useGetList('characteristics_descriptions', {
    target: 'characteristic_description',
    company: localStorage.getItem('company'),
    pagination: { page: 1, perPage: 100 },
    filter: { characteristic },
  });

  const characteristicName = dataCharacteristic?.[0];

  const handleChange = (...res) => {
    data.push(...res);
  };

  if (isLoadingCharacteristic || isLoading) {
    return <CircularProgress />;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '50px',
        width: '340px',
      }}
    >
      <AutocompleteInput
        disabled={isLoadingCharacteristic || !characteristic}
        fullWidth
        validate={required()}
        label="Descrição da Característica"
        source={source}
        choices={data}
        style={{ marginRight: '5px' }}
        optionText={(choice) => `${choice.description}`}
      />
      <DescricaoCaracteristicaRapida
        disabled={isLoadingCharacteristic || !characteristic}
        onChange={handleChange}
        source={source}
        characteristicName={characteristicName}
        characteristic={characteristic}
      />
    </div>
  );
};

export default DescricaoCaracteristicaNew;
