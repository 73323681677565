import portugueseMessages from '@henriko/ra-language-portuguese';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import BentoIcon from '@mui/icons-material/Bento';
import CategoryIcon from '@mui/icons-material/Category';
import ClassIcon from '@mui/icons-material/Class';
import DvrIcon from '@mui/icons-material/Dvr';
import FacebookIcon from '@mui/icons-material/Facebook';
import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import UserIcon from '@mui/icons-material/Group';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import InventoryIcon from '@mui/icons-material/Inventory';
import LibraryAddTwoToneIcon from '@mui/icons-material/LibraryAddTwoTone';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import LinkIcon from '@mui/icons-material/Link';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import PlaylistAddCircleTwoToneIcon from '@mui/icons-material/PlaylistAddCircleTwoTone';
import PostAddIcon from '@mui/icons-material/PostAdd';
import StorefrontIcon from '@mui/icons-material/Storefront';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import React from 'react';
import { Admin, Resource, CustomRoutes, localStorageStore } from 'react-admin';
import { Route, BrowserRouter } from 'react-router-dom';

import MyLayout from '../../components/Layout';
import theme from '../../config/theme';
import Instagram from '../../helpers/Instagram';
import authProvider from '../../services/auth';
import dataProvider from '../../services/dataProvider';
import {
  BannerInterno,
  BannerInternoEdit,
  BannerInternoCreate,
} from '../BannerInterno';
import { Banners, BannersEdit, BannersCreate } from '../Banners';
import { Autores, AutoresEdit, AutoresCreate } from '../Blog/Autores';
import {
  CategoriasBlog,
  CategoriasBlogEdit,
  CategoriasBlogCreate,
} from '../Blog/CategoriasBlog';
import { Posts, PostsEdit, PostsCreate } from '../Blog/Post';
import Dashboard from '../Dashboard';
import {
  Depoimentos,
  DepoimentosEdit,
  DepoimentosCreate,
} from '../Depoimentos';
import { Emails } from '../Emails';
import { Empresas, EmpresasEdit, EmpresasCreate } from '../Empresas';
import Login from '../Login';
import EsqueciSenha from '../Login/esqueciSenha';
import ResetarSenha from '../Login/resetarSenha';
import { Menus, MenusEdit, MenusCreate } from '../Menus';
import { Newsletters, NewslettersEdit, NewslettersCreate } from '../Newsletter';
import { Permissoes, PermissoesEdit, PermissoesCreate } from '../Permissoes';
import {
  Caracteristicas,
  CaracteristicasEdit,
  CaracteristicasCreate,
} from '../ProdutosServicos/Caracteristicas';
import {
  Categorias,
  CategoriasEdit,
  CategoriasCreate,
} from '../ProdutosServicos/Categorias';
import {
  DescricaoCaracteristicas,
  DescricaoCaracteristicasEdit,
  DescricaoCaracteristicasCreate,
} from '../ProdutosServicos/DescricaoCaracteristicas';
import { CreateEstruturaSecaoProduto, ListEstruturaSecaoProduto, EditEstruturaSecaoProduto } from '../ProdutosServicos/EstruturaSecaoProduto';
import { Marcas, MarcasEdit, MarcasCreate } from '../ProdutosServicos/Marcas';
import {
  ProdutosServicos,
  ProdutosServicosEdit,
  ProdutosServicosCreate,
} from '../ProdutosServicos/ProdutosServicos';
import { QuemSomos, QuemSomosEdit, QuemSomosCreate } from '../QuemSomos';
import {
  CriarSecoes,
  CriarSecoesEdit,
  CriarSecoesCreate,
} from '../SecaoGenerica/CriarSecao';
import {
  SecaoEmpresa,
  SecaoEmpresaEdit,
  SecaoEmpresaCreate,
} from '../SecaoGenerica/SecaoEmpresa';
import {
  SecaoUser,
  SecaoUserEdit,
  SecaoUserCreate,
} from '../SecaoGenerica/SecaoUser';
import { Social, SocialEdit, SocialCreate } from '../Social';
import { Usuarios, UserEdit, UserCreate } from '../Usuarios';

const messages = {
  pt: portugueseMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'pt', {
  allowMissing: true,
});

const store = localStorageStore();
store.setItem('sidebar.open', true);

const App = () => (
  <BrowserRouter>
    <Admin
      title="Gestor Web - Tytotech"
      loginPage={Login}
      dashboard={Dashboard}
      layout={MyLayout}
      theme={theme}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      store={store}
    >
      <CustomRoutes noLayout>
        <Route path="/insta" element={<Instagram />} />
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route path="/esqueciSenha" element={<EsqueciSenha />} />
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route path="/resetarSenha" element={<ResetarSenha />} />
      </CustomRoutes>
      {(permissions) => [
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_USUARIOS_LIST') ? (
          <Resource
            name="users"
            options={{ label: 'Usuários' }}
            icon={
              permissions.includes('SYS_ADMIN') ? GroupTwoToneIcon : UserIcon
            }
            list={Usuarios}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_USUARIOS_UPDATE')
                ? UserEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_USUARIOS_ADD')
                ? UserCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_BANNERS_LIST') ? (
          <Resource
            name="banners"
            options={{ label: 'Banners' }}
            icon={ViewCarouselIcon}
            list={Banners}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_BANNERS_UPDATE')
                ? BannersEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_BANNERS_ADD')
                ? BannersCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_EMPRESAS_LIST') ? (
          <Resource
            name="companies"
            options={{ label: 'Empresas' }}
            icon={
              permissions.includes('SYS_ADMIN')
                ? StorefrontTwoToneIcon
                : StorefrontIcon
            }
            list={Empresas}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_EMPRESAS_UPDATE')
                ? EmpresasEdit
                : null
            }
            create={permissions.includes('SYS_ADMIN') ? EmpresasCreate : null}
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_MIDIA_SOCIAL_LIST') ? (
          <Resource
            name="social_media"
            options={{ label: 'Mídias Sociais' }}
            icon={FacebookIcon}
            list={Social}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_MIDIA_SOCIAL_UPDATE')
                ? SocialEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_MIDIA_SOCIAL_ADD')
                ? SocialCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_BLOG_CATEGORIAS_LIST') ? (
          <Resource
            name="blog_categories"
            options={{ label: 'Categorias do Blog' }}
            icon={CategoryIcon}
            list={CategoriasBlog}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_BLOG_CATEGORIAS_UPDATE')
                ? CategoriasBlogEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_BLOG_CATEGORIAS_ADD')
                ? CategoriasBlogCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_BLOG_AUTORES_LIST') ? (
          <Resource
            name="blog_authors"
            options={{ label: 'Autores do Blog' }}
            icon={AccountBoxIcon}
            list={Autores}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_BLOG_AUTORES_UPDATE')
                ? AutoresEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_BLOG_AUTORES_ADD')
                ? AutoresCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_BLOG_POSTS_LIST') ? (
          <Resource
            name="blog_posts"
            options={{ label: 'Posts do Blog' }}
            icon={PostAddIcon}
            list={Posts}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_BLOG_POSTS_UPDATE')
                ? PostsEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_BLOG_POSTS_ADD')
                ? PostsCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_NEWSLETTER_LIST') ? (
          <Resource
            name="newsletter"
            options={{ label: 'Newsletter' }}
            icon={DvrIcon}
            list={Newsletters}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_NEWSLETTER_UPDATE')
                ? NewslettersEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_NEWSLETTER_ADD')
                ? NewslettersCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_EMAILS_LIST') ? (
          <Resource
            name="newsletter_emails"
            options={{ label: 'Emails da Newsletter' }}
            icon={ForwardToInboxIcon}
            list={Emails}
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_MENUS_LIST') ? (
          <Resource
            name="menus"
            options={{ label: 'Menus' }}
            icon={LinkIcon}
            list={Menus}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_MENUS_UPDATE')
                ? MenusEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_MENUS_ADD')
                ? MenusCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_QUEM_SOMOS_LIST') ? (
          <Resource
            name="company_information"
            options={{ label: 'Quem Somos' }}
            icon={ImportContactsIcon}
            list={QuemSomos}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_QUEM_SOMOS_UPDATE')
                ? QuemSomosEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_QUEM_SOMOS_ADD')
                ? QuemSomosCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_DEPOIMENTOS_LIST') ? (
          <Resource
            name="company_praises"
            options={{ label: 'Depoimentos' }}
            icon={MarkUnreadChatAltIcon}
            list={Depoimentos}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_DEPOIMENTOS_UPDATE')
                ? DepoimentosEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_DEPOIMENTOS_ADD')
                ? DepoimentosCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_MARCAS_LIST') ? (
          <Resource
            name="brands"
            options={{ label: 'Marcas' }}
            icon={LocalOfferIcon}
            list={Marcas}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_MARCAS_UPDATE')
                ? MarcasEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_MARCAS_ADD')
                ? MarcasCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_CATEGORIAS_LIST') ? (
          <Resource
            name="categories"
            options={{ label: 'Categorias' }}
            icon={ClassIcon}
            list={Categorias}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_CATEGORIAS_UPDATE')
                ? CategoriasEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_CATEGORIAS_ADD')
                ? CategoriasCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_CARACTERISTICAS_LIST') ? (
          <Resource
            name="characteristics"
            options={{ label: 'Características' }}
            icon={BentoIcon}
            list={Caracteristicas}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_CARACTERISTICAS_UPDATE')
                ? CaracteristicasEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_CARACTERISTICAS_ADD')
                ? CaracteristicasCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_CARACTERISTICAS_LIST') ? (
          <Resource
            name="characteristics_descriptions"
            options={{ label: 'Descrição' }}
            icon={BentoIcon}
            list={DescricaoCaracteristicas}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_CARACTERISTICAS_UPDATE')
                ? DescricaoCaracteristicasEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_CARACTERISTICAS_ADD')
                ? DescricaoCaracteristicasCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_PRODUTOS_SERVICOS_LIST') ? (
          <Resource
            name="products_services"
            options={{ label: 'Produto/Serviço' }}
            icon={InventoryIcon}
            list={ProdutosServicos}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_PRODUTOS_SERVICOS_UPDATE')
                ? ProdutosServicosEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_PRODUTOS_SERVICOS_ADD')
                ? ProdutosServicosCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ? (
          <Resource
            name="product_section_structure"
            options={{ label: 'Seção do produto' }}
            icon={InventoryIcon}
            list={ListEstruturaSecaoProduto}
            edit={EditEstruturaSecaoProduto}
            create={CreateEstruturaSecaoProduto}
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_SECAO_LIST') ? (
          <Resource
            name="section_informations"
            options={{ label: 'Seção' }}
            icon={LibraryBooksOutlinedIcon}
            list={SecaoUser}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_SECAO_UPDATE')
                ? SecaoUserEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_SECAO_ADD')
                ? SecaoUserCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN') ||
          permissions.includes('ROLE_BANNER_INTERNO_LIST') ? (
          <Resource
            name="intern_banners"
            options={{ label: 'Banner Interno' }}
            icon={ViewCarouselOutlinedIcon}
            list={BannerInterno}
            edit={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_BANNER_INTERNO_UPDATE')
                ? BannerInternoEdit
                : null
            }
            create={
              permissions.includes('SYS_ADMIN') ||
                permissions.includes('ROLE_BANNER_INTERNO_ADD')
                ? BannerInternoCreate
                : null
            }
          />
        ) : null,
        permissions.includes('SYS_ADMIN')
          ? [
            <Resource
              key="sections"
              name="sections"
              options={{ label: 'Criar Seção (ADMIN)' }}
              icon={LibraryAddTwoToneIcon}
              list={CriarSecoes}
              edit={CriarSecoesEdit}
              create={CriarSecoesCreate}
            />,
            <Resource
              key="company_sections"
              name="company_sections"
              options={{ label: 'Linkar Seção (ADMIN)' }}
              icon={PlaylistAddCircleTwoToneIcon}
              list={SecaoEmpresa}
              edit={SecaoEmpresaEdit}
              create={SecaoEmpresaCreate}
            />,
            <Resource
              key="permissions"
              name="permissions"
              icon={FactCheckTwoToneIcon}
              options={{ label: 'Permissões' }}
              list={Permissoes}
              edit={PermissoesEdit}
              create={PermissoesCreate}
            />,
          ]
          : [
            <Resource key="permissions" name="permissions" />,
            <Resource key="company_sections" name="company_sections" />,
            <Resource key="sections" name="sections" />,
          ],
      ]}
    </Admin>
  </BrowserRouter>
);

export default App;
