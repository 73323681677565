import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  usePermissions,
  useRecordContext,
  SelectInput,
  Filter,
  useNotify,
  ListButton,
  BooleanInput,
} from 'react-admin';

import { sectionChoices, productStructChoices } from '../../../helpers/sectionChoices';
import {
  Text,
  Number,
  RichText,
  Image,
  File,
  Boolean,
  Date,
  ImageOne,
  FileOne,
} from './Fields';

const choices = productStructChoices({
  Text,
  Number,
  RichText,
  Boolean,
  Date,
});

const CriarSecoesTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return <span>Seção {record ? `"${record.title}"` : ''}</span>;
};

const SecoesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {!permissions.isLoading &&
      permissions.permissions.includes('SYS_ADMIN') && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

export const ListEstruturaSecaoProduto = (props) => {
  const permissions = usePermissions();

  return (
    <List
      perPage={25}
      title="Listar Seções do Produto (ADMIN)"
      {...props}
    >
      <Datagrid
        bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      >
        <TextField label="ID" source="id" />
        <TextField label="Nome" source="name" />
        {!permissions.isLoading &&
          permissions.permissions.includes('SYS_ADMIN') && <EditButton />}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    className="botoesUpdate"
    style={{ justifyContent: 'space-between' }}
  >
    <div
      style={{
        width: '250px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton alwaysEnable />
      <ListButton
        size="medium"
        variant="outlined"
        label="cancelar"
        icon={<CloseIcon />}
      />
    </div>
    {permissions &&
      permissions.permissions &&
      permissions.permissions.includes('SYS_ADMIN') && (
        <DeleteButton size="medium" />
      )}
  </Toolbar>
);

export const EditEstruturaSecaoProduto = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Edit redirect="list" title={<CriarSecoesTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput source="name" label="Nome da Seção" validate={required()} />
        <TextInput source="key" label="Chave" validate={required()} />
        <ArrayInput source="fields_structures" label="Campos">
          <SimpleFormIterator>
            <SelectInput
              source="field"
              label="Campo"
              choices={choices}
              validate={required()}
              disabled={true}
            >
              <TextField source="name" validate={required()} />
            </SelectInput>
            <TextInput
              source="key"
              label="Chave"
              validate={required()}
              format={(value) =>
                value?.replace(/[^A-Z0-9_-]/gi, '').toLowerCase()
              }
            />
            <TextInput
              source="label"
              label="Label (Tooltip)"
              validate={required()}
            />
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                getSource('field');

                if (!scopedFormData) {
                  return (
                    <div style={{ margin: '5px 0 20px 0', color: 'grey' }}>
                      Selecione um tipo para ter uma pré-visualização
                    </div>
                  );
                }

                const selectedChoice = choices.find(
                  (c) => c.id === scopedFormData.field,
                );

                if (!selectedChoice) {
                  return (
                    <div style={{ margin: '5px 0 20px 0', color: 'grey' }}>
                      Selecione um tipo para ter uma pré-visualização
                    </div>
                  );
                }

                return <selectedChoice.element />;
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput
          source="allow_many"
          label="Permitir vários"
          validate={required()}
          disabled={true}
        />
      </SimpleForm>
    </Edit>
  );
};

export const CreateEstruturaSecaoProduto = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Create redirect="list" {...props} title="Nova Seção">
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput source="name" label="Nome da Seção" validate={required()} />
        <TextInput source="key" label="Chave" validate={required()} />
        <ArrayInput source="fields_structures" label="Campos">
          <SimpleFormIterator>
            <SelectInput
              source="field"
              label="Campo"
              choices={choices}
              validate={required()}
            >
              <TextField source="name" validate={required()} />
            </SelectInput>
            <TextInput
              source="key"
              label="Chave"
              validate={required()}
              format={(value) =>
                value?.replace(/[^A-Z0-9_-]/gi, '').toLowerCase()
              }
            />
            <TextInput
              source="label"
              label="Label (Tooltip)"
              validate={required()}
            />
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                getSource('field');

                if (!scopedFormData) {
                  return (
                    <div style={{ margin: '5px 0 20px 0', color: 'grey' }}>
                      Selecione um tipo para ter uma pré-visualização
                    </div>
                  );
                }

                const selectedChoice = choices.find(
                  (c) => c.id === scopedFormData.field,
                );

                if (!selectedChoice) {
                  return (
                    <div style={{ margin: '5px 0 20px 0', color: 'grey' }}>
                      Selecione um tipo para ter uma pré-visualização
                    </div>
                  );
                }

                return <selectedChoice.element />;
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput
          source="allow_many"
          label="Permitir vários"
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};

export default CreateEstruturaSecaoProduto;
