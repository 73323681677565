import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import {
  RichTextInput,
  RichTextInputToolbar,
  FormatButtons,
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
} from 'ra-input-rich-text';
import React, { useMemo, useState } from 'react';
import {
  List,
  Datagrid,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ImageInput,
  Filter,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  TextField,
  BooleanInput,
  usePermissions,
  useRecordContext,
  AutocompleteInput,
  useGetList,
  ReferenceField,
  FileInput,
  ImageField,
  BooleanField,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  useNotify,
  ListButton,
  CheckboxGroupInput,
  RadioButtonGroupInput,
  SelectInput,
  NumberInput,
  Button,
  DateInput,
} from 'react-admin';
import { useCookies } from 'react-cookie';
import { useWatch } from 'react-hook-form';

import FilePreview from '../../../components/FilePreview';
import ImagePreview from '../../../components/ImagePreviewWithShowFirst';
import ShortField from '../../../components/ShortField';
import { FILE_MAX_ZIZE, IMAGE_MAX_SIZE } from '../../../helpers/constants';
import CaracteristicaNew from '../Caracteristicas/CaracteristicaNew';
import DescricaoCaracteristicaNew from '../DescricaoCaracteristicas/DescricaoCaracteristicaNew';
import ToggleProdutoServico from './toggleProdutoServico';

const ProdutosServicosTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return <span>Produto/Serviço {record.title}</span>;
};

const ProdutosServicosFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_PRODUTOS_SERVICOS_DELETE')) && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

const CategoriaGet = () => {
  const isService = useWatch({ name: 'is_service' });
  const { data, isLoading } = useGetList(
    'categories',
    {
      target: 'category',
      company: localStorage.getItem('company'),
      pagination: { page: 1, perPage: 100 },
      filter: {
        ...(isService !== undefined && { is_service: isService }),
      },
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  if (isLoading) return <CircularProgress />;

  return (
    <AutocompleteInput
      fullWidth
      validate={required()}
      label="Categoria"
      source="category"
      choices={data}
      style={{ marginRight: '50px' }}
      optionText={(choice) => `${choice.title}`}
    />
  );
};

const MarcaGet = () => {
  const { data, isLoading } = useGetList(
    'brands',
    {
      target: 'brand',
      company: localStorage.getItem('company'),
      pagination: { page: 1, perPage: 100 },
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  if (isLoading) return <CircularProgress />;

  return (
    <AutocompleteInput
      fullWidth
      label="Marca"
      source="brand"
      choices={data}
      style={{ marginLeft: '50px' }}
      optionText={(choice) => `${choice.name}`}
    />
  );
};

const CategoriaMarca = ({ permissions }) => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
    }}
  >
    {!permissions.isLoading &&
      (permissions.permissions.includes('ROLE_CATEGORIAS_LIST') ||
        permissions.permissions.includes('SYS_ADMIN')) && <CategoriaGet />}
    {!permissions.isLoading &&
      (permissions.permissions.includes('ROLE_MARCAS_LIST') ||
        permissions.permissions.includes('SYS_ADMIN')) && <MarcaGet />}
  </div>
);

const CharacteristicsGet = () => {
  const { data, isLoading } = useGetList(
    'companies',
    {
      target: 'company',
      filter: {
        id: localStorage.getItem('company'),
        company: localStorage.getItem('company'),
        hasCharacteristics: true,
      },
      pagination: { page: 1, perPage: 1 },
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  if (isLoading) return <CircularProgress />;
  if (!data || !data[0].prod_serv_has_characteristics) return null;

  return (
    <ArrayInput source="characteristicsList" label="Características do Produto">
      <SimpleFormIterator disableReordering>
        <FormDataConsumer>
          {({ getSource }) => (
            <CaracteristicaNew source={getSource('characteristic')} />
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ getSource, scopedFormData }) => (
            <DescricaoCaracteristicaNew
              characteristic={scopedFormData.characteristic}
              source={getSource('description')}
              label="Descrição"
              validate={required()}
              fullWidth
            />
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const IsServiceGet = () => {
  const { data, isLoading } = useGetList('companies', {
    target: 'company',
    filter: {
      id: localStorage.getItem('company'),
      company: localStorage.getItem('company'),
      differentiateService: true,
    },
    pagination: { page: 1, perPage: 1 },
  });

  if (isLoading) return <CircularProgress />;
  if (!data || !data[0].should_differentiate_service) return null;

  return <BooleanInput label="É Serviço?" source="is_service" />;
};

const LinkWhatsGet = () => {
  const { data, isLoading } = useGetList(
    'companies',
    {
      target: 'company',
      filter: {
        id: localStorage.getItem('company'),
        company: localStorage.getItem('company'),
        onlyLinkWhats: true,
      },
      pagination: { page: 1, perPage: 1 },
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  if (isLoading) return <CircularProgress />;
  if (!data || !data[0].message_link_product_service) return null;

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
      }}
    >
      <TextInput
        label="Titulo do Botão"
        fullWidth
        source="button_title"
        style={{ marginRight: '50px' }}
        validate={required()}
      />
      <TextInput
        label="Texto do WhatsApp"
        fullWidth
        source="button_text"
        style={{ marginLeft: '50px' }}
        validate={required()}
      />
    </div>
  );
};

export const ProdutosServicos = (props) => {
  const permissions = usePermissions();

  const { data, isLoading } = useGetList('companies', {
    target: 'company',
    filter: {
      id: localStorage.getItem('company'),
      company: localStorage.getItem('company'),
      differentiateService: true,
    },
    pagination: { page: 1, perPage: 1 },
  });

  return (
    <List
      perPage={25}
      title="Produtos/Serviços"
      filters={<ProdutosServicosFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
    >
      <Datagrid
        bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      >
        <ToggleProdutoServico label="Ativo" source="disabled" {...props} />
        <ImageField
          sortable={false}
          source="image"
          label="Imagem"
          title="Imagem"
        />
        <ShortField label="Título" source="title" />
        <ShortField label="Descrição" source="description" />
        {!permissions.isLoading &&
          (permissions.permissions.includes('SYS_ADMIN') ||
            permissions.permissions.includes('ROLE_CATEGORIAS_LIST')) && (
            <ReferenceField
              label="Categoria"
              source="category"
              reference="categories"
            >
              <TextField source="title" />
            </ReferenceField>
          )}
        {!permissions.isLoading &&
          (permissions.permissions.includes('SYS_ADMIN') ||
            permissions.permissions.includes('ROLE_MARCAS_LIST')) && (
            <ReferenceField label="Marca" source="brand" reference="brands">
              <TextField source="name" />
            </ReferenceField>
          )}

        <ShortField label="Linguagem" source="language" transform={txt => {
          switch (txt) {
            case 'portuguese':
              return 'Português';
            case 'english':
              return 'Inglês';
            case 'spanish':
              return 'Espanhol';
            default:
              return 'Indefinido';
          }
        }} />

        {!permissions.isLoading &&
          !isLoading &&
          data &&
          data[0].should_differentiate_service &&
          (permissions.permissions.includes('SYS_ADMIN') ||
            permissions.permissions.includes('ROLE_EMPRESAS_LIST')) && (
            <BooleanField label="É Serviço" source="is_service" />
          )}
        {!permissions.isLoading &&
          (permissions.permissions.includes('SYS_ADMIN') ||
            permissions.permissions.includes(
              'ROLE_PRODUTOS_SERVICOS_UPDATE',
            )) && <EditButton />}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    className="botoesUpdate"
    style={{ justifyContent: 'space-between' }}
  >
    <div
      style={{
        width: '250px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton alwaysEnable />
      <ListButton
        size="medium"
        variant="outlined"
        label="cancelar"
        icon={<CloseIcon />}
      />
    </div>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_PRODUTOS_SERVICOS_DELETE')) && (
        <DeleteButton size="medium" />
      )}
  </Toolbar>
);

export const ProdutosServicosEdit = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const [cookies] = useCookies([]);

  const languages = useMemo(() => {
    const list = [
      { id: 'portuguese', name: 'Português' },
    ];
    if (cookies.english === '1') {
      list.push({ id: 'english', name: 'Inglês' });
    }
    if (cookies.spanish === '1') {
      list.push({ id: 'spanish', name: 'Espanhol' });
    }
    return list;
  }, [cookies.spanish, cookies.english]);

  return (
    <Edit redirect="list" title={<ProdutosServicosTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        {!permissions.isLoading &&
          (permissions.permissions.includes('ROLE_EMPRESAS_LIST') ||
            permissions.permissions.includes('SYS_ADMIN')) && <IsServiceGet />}

        <RadioButtonGroupInput defaultValue={'portuguese'} source="language" label="Idioma" choices={languages} />
        <CategoriaMarca permissions={permissions} />
        <TextInput
          label="Título"
          fullWidth
          source="title"
          validate={required()}
        />
        <RichTextInput
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <AlignmentButtons />
              <FormatButtons />
              <ColorButtons />
              <ListButtons />
              <LinkButtons />
              <QuoteButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          label="Descrição"
          source="description"
          fullWidth
        />
        <RichTextInput
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <AlignmentButtons />
              <FormatButtons />
              <ColorButtons />
              <ListButtons />
              <LinkButtons />
              <QuoteButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          label="Especificação"
          source="specification"
          fullWidth
        />
        <RichTextInput
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <AlignmentButtons />
              <FormatButtons />
              <ColorButtons />
              <ListButtons />
              <LinkButtons />
              <QuoteButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          label="Garantia"
          source="warranty"
          fullWidth
        />
        {!permissions.isLoading &&
          (permissions.permissions.includes('ROLE_EMPRESAS_LIST') ||
            permissions.permissions.includes('SYS_ADMIN')) && <LinkWhatsGet />}
        {!permissions.isLoading &&
          ((permissions.permissions.includes('ROLE_CARACTERISTICAS_LIST') &&
            permissions.permissions.includes('ROLE_EMPRESAS_LIST')) ||
            permissions.permissions.includes('SYS_ADMIN')) && (
            <CharacteristicsGet />
          )}
        <ImageInput
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 35MB', { type: 'warning' });
              }
            },
          }}
          multiple
          label="Fotos do Produto/Serviço (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImagePreview {...props} />
        </ImageInput>
        <FileInput
          maxSize={FILE_MAX_ZIZE}
          source="docs"
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 35MB', { type: 'warning' });
              }
            },
          }}
          label="Arquivos do Produto/Serviço (pdf, doc, docx, xls, xlsx, ppt, pptx, odt, ods, odp, zip, 7zip)"
          multiple
          accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.presentation, application/zip, application/x-7z-compressed"
        >
          <FilePreview {...props} />
        </FileInput>
        <ProductSections />
        <BooleanInput label="Desabilitado ?" source="disabled" />
      </SimpleForm>
    </Edit>
  );
};

const ProductSections = ({ create = false }) => {
  const { data, isLoading } = useGetList('product_section_structure');

  React.useEffect(() => console.log('structures: ', data), [data]);

  if (isLoading) return <CircularProgress />;

  return data && data.length ? <section style={{ borderTop: '2px solid #3E4095', width: '100%', borderBottom: '2px solid #3E4095' }}>
    {
      data.map((section, idx) => (
        <section key={idx} style={{ marginTop: '2rem' }}>
          <span style={{ color: '#3E4095' }}>{section.name}</span>
          {
            (() => {
              const test = [<TextInput source="s1" label="Campo1" key="1" />, <TextInput source="s2" label="Campo2" key="2" />];
              const fields = section.fields_structures.map((structure, i) => {
                const sourceLabel = section.allow_many ? `${structure.key}` : `sections_group.section_${section.id}.data.${structure.key}`;

                const inputsProps = {
                  label: structure.label,
                  fullWidth: true,
                  source: sourceLabel,
                };

                const inputs = [
                  <TextInput {...inputsProps} key={i} />,
                  <NumberInput {...inputsProps} key={i} />,
                  <BooleanInput {...inputsProps} key={i} />,
                  <RichTextInput {...inputsProps} key={i} />,
                  <DateInput {...inputsProps} key={i} />,
                ];

                const inputsIds = ['text', 'number', 'boolean', 'richText', 'date'];

                const selectedInputIdx = inputsIds.findIndex(id => id === structure.field) || 0;

                return inputs[selectedInputIdx];
              });

              console.log(fields);
              return section.allow_many ?
                <ArrayInput source={`sections_group.section_${section.id}.data`} label="Campos">
                  <SimpleFormIterator>
                    <></>
                    {
                      fields ?? <></>
                    }

                  </SimpleFormIterator>
                </ArrayInput> : fields;
            })()
          }
        </section>
      ))
    }
  </section> : <></>;
};

export const ProdutosServicosCreate = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const [cookies] = useCookies([]);

  const languages = useMemo(() => {
    const list = [
      { id: 'portuguese', name: 'Português' },
    ];
    if (cookies.english === '1') {
      list.push({ id: 'english', name: 'Inglês' });
    }
    if (cookies.spanish === '1') {
      list.push({ id: 'spanish', name: 'Espanhol' });
    }
    return list;
  }, [cookies.spanish, cookies.english]);

  return (
    <Create redirect="list" {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        {!permissions.isLoading &&
          (permissions.permissions.includes('ROLE_EMPRESAS_LIST') ||
            permissions.permissions.includes('SYS_ADMIN')) && <IsServiceGet />}

        <RadioButtonGroupInput defaultValue={'portuguese'} source="language" label="Idioma" choices={languages} />

        <CategoriaMarca permissions={permissions} />
        <TextInput
          label="Título"
          fullWidth
          source="title"
          validate={required()}
        />
        <RichTextInput
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <AlignmentButtons />
              <FormatButtons />
              <ColorButtons />
              <ListButtons />
              <LinkButtons />
              <QuoteButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          validate={required()}
          label="Descrição"
          source="description"
          fullWidth
        />
        <RichTextInput
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <AlignmentButtons />
              <FormatButtons />
              <ColorButtons />
              <ListButtons />
              <LinkButtons />
              <QuoteButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          label="Especificação"
          source="specification"
          fullWidth
        />
        <RichTextInput
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <AlignmentButtons />
              <FormatButtons />
              <ColorButtons />
              <ListButtons />
              <LinkButtons />
              <QuoteButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          label="Garantia"
          source="warranty"
          fullWidth
        />
        {!permissions.isLoading &&
          (permissions.permissions.includes('ROLE_EMPRESAS_LIST') ||
            permissions.permissions.includes('SYS_ADMIN')) && <LinkWhatsGet />}
        {!permissions.isLoading &&
          ((permissions.permissions.includes('ROLE_CARACTERISTICAS_LIST') &&
            permissions.permissions.includes('ROLE_EMPRESAS_LIST')) ||
            permissions.permissions.includes('SYS_ADMIN')) && (
            <CharacteristicsGet />
          )}
        <ImageInput
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 35MB', { type: 'warning' });
              }
            },
          }}
          multiple
          label="Fotos do Produto/Serviço (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImagePreview {...props} />
        </ImageInput>
        <FileInput
          maxSize={FILE_MAX_ZIZE}
          source="docs"
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 35MB', { type: 'warning' });
              }
            },
          }}
          label="Arquivos do Produto/Serviço (pdf, doc, docx, xls, xlsx, ppt, pptx, odt, ods, odp)"
          multiple
          accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.presentation"
        >
          <FilePreview {...props} />
        </FileInput>
        <ProductSections create={true} />
        <BooleanInput label="Desabilitado ?" source="disabled" />
      </SimpleForm>
    </Create>
  );
};

export default ProdutosServicos;
