import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  required,
  useRecordContext,
} from 'react-admin';

const PermissoesTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return <span>Permissão {record ? `"${record.name}"` : ''}</span>;
};

const PermissoesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

export const Permissoes = (props) => (
  <List
    perPage={25}
    title="Permissões"
    filters={<PermissoesFilter />}
    {...props}
  >
    <Datagrid>
      <TextField label="Permissão" source="role" />
      <TextField label="Nome" source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

export const PermissoesEdit = (props) => (
  <Edit redirect="list" title={<PermissoesTitle />} {...props}>
    <SimpleForm>
      <TextInput
        label="Permissão"
        validate={required()}
        source="role"
        fullWidth
      />
      <TextInput label="Nome" validate={required()} source="name" fullWidth />
    </SimpleForm>
  </Edit>
);

export const PermissoesCreate = (props) => (
  <Create redirect="list" {...props} title="Nova Permissão">
    <SimpleForm>
      <TextInput
        label="Permissão"
        validate={required()}
        source="role"
        fullWidth
      />
      <TextInput label="Nome" validate={required()} source="name" fullWidth />
    </SimpleForm>
  </Create>
);

export default Permissoes;
