import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import {
  List,
  Datagrid,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  TextField,
  usePermissions,
  useRecordContext,
  useNotify,
  ListButton,
} from 'react-admin';

import ToggleMarca from './toggleMarca';

const MarcasTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return <span>Marca {record.name}</span>;
};

const MarcasFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_MARCAS_DELETE')) && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

export const Marcas = (props) => {
  const permissions = usePermissions();

  return (
    <List
      perPage={25}
      title="Marcas"
      filters={<MarcasFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
    >
      <Datagrid
        bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      >
        <ToggleMarca />
        <TextField label="Nome" source="name" />
        {!permissions.isLoading &&
          (permissions.permissions.includes('SYS_ADMIN') ||
            permissions.permissions.includes('ROLE_MARCAS_UPDATE')) && (
            <EditButton />
          )}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    className="botoesUpdate"
    style={{ justifyContent: 'space-between' }}
  >
    <div
      style={{
        width: '250px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton alwaysEnable />
      <ListButton
        size="medium"
        variant="outlined"
        label="cancelar"
        icon={<CloseIcon />}
      />
    </div>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_MARCAS_DELETE')) && (
        <DeleteButton size="medium" />
      )}
  </Toolbar>
);

export const MarcasEdit = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Edit redirect="list" title={<MarcasTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput label="Nome" fullWidth source="name" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

export const MarcasCreate = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Create redirect="list" {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput label="Nome" fullWidth source="name" validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export default Marcas;
