import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import React, { useState, useEffect } from 'react';

const ModalFilename = ({ open, handleClose, filenameDefault }) => {
  const [filename, setFilename] = useState('');
  useEffect(() => {
    setFilename(filenameDefault || '');
  }, [filenameDefault]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose('cancelar', filenameDefault);
        setFilename(filenameDefault || '');
      }}
      aria-labelledby="form-dialog-title"
      onKeyUp={(key) => {
        if (key.key === 'Enter') {
          handleClose('salvar', filename);
        }
      }}
    >
      <DialogTitle id="form-dialog-title">Renomear Arquivo</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Escreva o nome do arquivo no campo abaixo
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nome do Arquivo"
          fullWidth
          value={filename}
          onChange={(e) => setFilename(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose('salvar', filenameDefault);
            setFilename(filenameDefault || '');
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button onClick={() => handleClose('salvar', filename)} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalFilename;
