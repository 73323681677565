/* eslint-disable react-hooks/exhaustive-deps */
import cep from 'cep-promise';
import React, { useState, useEffect } from 'react';
import { TextInput, required } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import { MascaraCEP } from '../../helpers/masks';

const CEPAuto = () => {
  const [procura, setProcura] = useState(false);
  const [erroCEP, setErroCEP] = useState(false);
  const [vaiProcurar, setVaiProcurar] = useState(false);

  const { setValue } = useFormContext();

  const procuraCEP = useWatch({ name: 'cep' });

  useEffect(() => {
    if (procuraCEP && procuraCEP.includes('_')) {
      setVaiProcurar(true);
    }

    if (vaiProcurar && procuraCEP && !procuraCEP.includes('_')) {
      setVaiProcurar(false);
      setProcura(true);
      setValue('state', '...');
      setValue('city', '...');
      setValue('neighborhood', '...');
      setValue('address', '...');
      cep(procuraCEP.replace('-', '')).then(
        // Deu tudo ok
        (result) => {
          setProcura(false);
          setErroCEP(false);
          setValue('state', result.state);
          setValue('city', result.city);
          setValue('neighborhood', result.neighborhood);
          if (result.street.includes(' - ')) {
            setValue('address', result.street.split(' - ')[0]);
          } else {
            setValue('address', result.street);
          }
        },
        // Deu algum erro
        () => {
          setProcura(false);
          setErroCEP(true);
          setValue('state', '');
          setValue('city', '');
          setValue('neighborhood', '');
          setValue('address', '');
        },
      );
    }
  }, [procuraCEP]);

  return (
    <div className="CepDiv">
      <TextInput
        label="CEP"
        source="cep"
        helperText={erroCEP ? 'CEP inválido' : ''}
        InputProps={{
          inputComponent: MascaraCEP,
        }}
        validate={required()}
        style={{ marginRight: '10px' }}
      />
      <TextInput
        validate={required()}
        label="Endereço"
        source="address"
        disabled={procura}
        style={{ marginRight: '10px' }}
      />
      <TextInput
        validate={required()}
        label="Número"
        source="number"
        style={{ marginRight: '10px' }}
      />
      <TextInput
        validate={required()}
        label="Bairro"
        source="neighborhood"
        disabled={procura}
        style={{ marginRight: '10px' }}
      />
      <TextInput
        label="Complemento"
        source="complement"
        style={{ marginRight: '10px' }}
      />
      <TextInput
        validate={required()}
        label="Estado"
        source="state"
        disabled
        style={{ marginRight: '10px' }}
      />
      <TextInput
        validate={required()}
        label="Cidade"
        source="city"
        disabled
        style={{ marginRight: '10px' }}
      />
    </div>
  );
};
export default CEPAuto;
