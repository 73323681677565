import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  Form,
  CreateBase,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

function DescricaoCaracteristicaRapida({
  onChange,
  source,
  characteristic,
  disabled,
  characteristicName,
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { isLoading }] = useCreate('characteristics_descriptions');
  const notify = useNotify();
  const { setValue } = useFormContext();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      'characteristics_descriptions',
      { data: values },
      {
        onSuccess: (data) => {
          setValue(source, data.id);
          onChange(data);
          setShowDialog(false);
        },
        onError: () => {
          notify('Já existe uma Característica com essa Descrição!', 'warning');
        },
      },
    );
  };

  return (
    <>
      <Button
        onClick={handleClick}
        label="ra.action.create"
        variant="outlined"
        style={{ marginBottom: '10px' }}
        disabled={disabled}
      >
        <AddIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Criar Descrição da Característica"
      >
        <DialogTitle>
          Criar Descrição da Característica {`"${characteristicName?.name}"`}
        </DialogTitle>
        <CreateBase
          resource="characteristics_descriptions"
          id="characteristics_descriptions"
          redirect={false}
        >
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <ReferenceInput
                source="characteristic"
                reference="characteristics"
                validate={required()}
                defaultValue={characteristicName?.id}
                fullWidth
              >
                <SelectInput
                  label="Característica"
                  disabled
                  optionText="name"
                  defaultValue={characteristicName?.id}
                />
              </ReferenceInput>
              <TextInput
                label="Nome"
                source="description"
                validate={required()}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                label="ra.action.cancel"
                onClick={handleCloseClick}
                disabled={isLoading}
              >
                <CancelIcon />
              </Button>
              <SaveButton disabled={isLoading} />
            </DialogActions>
          </Form>
        </CreateBase>
      </Dialog>
    </>
  );
}

export default DescricaoCaracteristicaRapida;
