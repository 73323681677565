import React from 'react';
import { IMaskInput } from 'react-imask';

export const MascaraCEP = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00000-000"
      placeholder="____-___"
      lazy={false}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const MascaraCNPJ = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00.000.000/0000-00"
      placeholder="__.___.___/____-__"
      lazy={false}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const MascaraCPF = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000.000.000-00"
      placeholder="___.___.___-__"
      lazy={false}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const MascaraTelefone = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(00)0000-0000"
      placeholder="(__)____-____"
      lazy={false}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const MascaraCelular = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(00)00000-0000"
      placeholder="(__)_____-____"
      lazy={false}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const MascaraTelefoneOuCelular = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="\(\d{2})\s9?\d{4}-\d{4}"
      placeholder="(__)____-____"
      lazy={false}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const validateCelPhoneFixOr0800Number = (value) => {
  const celPhoneRegex = /^(\+?\d{2} ?)?(\(\d{2}\)|\d{2})?\s?\d{4,5}\-?\d{4}$/; // eslint-disable-line
  const fixPhoneRegex = /^(\+?\d{2}?\s?)?(\(\d{2}\)|\d{2})?\s?\d{4}\-?\d{4}$/; // eslint-disable-line
  const _0800PhoneRegex = /^(\+?\d{2}?\s?)?0\s?800\s?\d{3}\s?\d{4}$/; // eslint-disable-line

  if (celPhoneRegex.test(value) || fixPhoneRegex.test(value) || _0800PhoneRegex.test(value)) {
    return undefined;
  }
  return 'O valor digitado deve ser uma número de telefone válido ( celular, fixo ou 0800 )';
};
