import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';

const axios = require('axios');

const Instagram = () => {
  const code = window.location.href.split('/insta/?code=')[1];
  const token = localStorage.getItem('token');
  const company = localStorage.getItem('company');
  const [mounted, setMounted] = useState(false);
  const [text, setText] = useState(<CircularProgress disableShrink />);

  useEffect(() => {
    setMounted(true);
    if (mounted) {
      const config = {
        headers: {
          company,
          authorization: `Bearer ${token}`,
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/instagram/auth?code=${code}`,
          {
            url: `${window.location.origin}/insta/`,
          },
          config,
        )
        .then(() => {
          window.close();
        })
        .catch(({ response: error }) => {
          setText(
            <>
              <p>Algo deu errado, tente novamente mais tarde!</p>
              <p>Se o problema persistir, entre em contato com o suporte.</p>
              <p>Erro: {error.data.error_message}</p>
            </>,
          );
        });
    }
  }, [code, company, mounted, token]);

  return (
    <div
      style={{
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      {text}
    </div>
  );
};

export default Instagram;
