import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useMemo } from 'react';
import {
  List,
  Datagrid,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ImageInput,
  ImageField,
  Filter,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  TextField,
  BooleanInput,
  usePermissions,
  useRecordContext,
  useNotify,
  ReferenceInput,
  ReferenceField,
  SelectInput,
  ListButton,
  useGetList,
  FunctionField,
  RadioButtonGroupInput,
} from 'react-admin';
import { useCookies } from 'react-cookie';

import ShortField from '../../../components/ShortField';
import { IMAGE_MAX_SIZE } from '../../../helpers/constants';
import ToggleCategoria from './toggleCategoria';

const CategoriasTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return <span>Categoria {record.title}</span>;
};

const CategoriasFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_CATEGORIAS_DELETE')) && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

export const Categorias = (props) => {
  const permissions = usePermissions();
  const { data, isLoading } = useGetList('companies', {
    target: 'company',
    filter: {
      id: localStorage.getItem('company'),
      company: localStorage.getItem('company'),
      differentiateService: true,
    },
    pagination: { page: 1, perPage: 1 },
  });

  return (
    <List
      perPage={25}
      title="Categorias"
      filters={<CategoriasFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
    >
      <Datagrid
        bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      >
        <ToggleCategoria label="Ativo" source="disabled" />
        <ImageField
          sortable={false}
          source="image"
          label="Imagem"
          title="Categoria"
        />
        <TextField label="Título" source="title" />
        <ShortField label="Descrição" source="description" />
        <ReferenceField
          label="Categoria Pai"
          source="category_id"
          reference="categories"
        >
          <TextField source="title" />
        </ReferenceField>

        {!permissions.isLoading &&
          !isLoading &&
          data &&
          data[0].should_differentiate_service &&
          (permissions.permissions.includes('SYS_ADMIN') ||
            permissions.permissions.includes('ROLE_EMPRESAS_LIST')) && (
            <FunctionField
              label="Tipo"
              source="type"
              render={(record) => {
                if (record.type === 'PRODUTO') return 'Produto';
                if (record.type === 'SERVICO') return 'Serviço';
                if (record.type === 'AMBOS') return 'Ambos';
                return record.type;
              }}
            />
          )}
        {/* <ShortField label="Idioma" source="language" transform={txt => {
            switch (txt) {
              case 'portuguese':
                return 'Português';
              case 'english':
                return 'Inglês';
              case 'spanish':
                return 'Espanhol';
              default:
                return 'Indefinido';
            }
        }} /> */}
        {!permissions.isLoading &&
          (permissions.permissions.includes('SYS_ADMIN') ||
            permissions.permissions.includes('ROLE_CATEGORIAS_UPDATE')) && (
            <EditButton />
          )}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    className="botoesUpdate"
    style={{ justifyContent: 'space-between' }}
  >
    <div
      style={{
        width: '250px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton alwaysEnable />
      <ListButton
        size="medium"
        variant="outlined"
        label="cancelar"
        icon={<CloseIcon />}
      />
    </div>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_CATEGORIAS_DELETE')) && (
        <DeleteButton size="medium" />
      )}
  </Toolbar>
);

const CategoriaPai = () => {
  const record = useRecordContext();

  return (
    <ReferenceInput
      label="Categoria Pai"
      source="category_id"
      reference="categories"
      filter={{
        category_id: record ? record.id : undefined,
      }}
      allowEmpty
    >
      <SelectInput fullWidth optionText="title" label="Categoria Pai" />
    </ReferenceInput>
  );
};

const CategoryTypeGet = () => {
  const { data, isLoading } = useGetList('companies', {
    target: 'company',
    filter: {
      id: localStorage.getItem('company'),
      company: localStorage.getItem('company'),
      differentiateService: true,
    },
    pagination: { page: 1, perPage: 1 },
  });

  if (isLoading) return <CircularProgress />;
  if (!data || !data[0].should_differentiate_service) return null;

  return (
    <SelectInput
      fullWidth
      source="type"
      label="Tipo de Categoria"
      defaultValue="PRODUTO"
      choices={[
        { id: 'PRODUTO', name: 'Produto' },
        { id: 'SERVICO', name: 'Serviço' },
        { id: 'AMBOS', name: 'Ambos' },
      ]}
      optionText="name"
    />
  );
};

export const CategoriasEdit = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const [cookies] = useCookies([]);

  // const languages = useMemo(() => {
  //   const list = [
  //     { id: 'portuguese', name: 'Português' },
  //   ];
  //   if (cookies.english === '1') {
  //     list.push({ id: 'english', name: 'Inglês' });
  //   }
  //   if (cookies.spanish === '1') {
  //     list.push({ id: 'spanish', name: 'Espanhol' });
  //   }
  //   return list;
  // }, [cookies.spanish, cookies.english]);
  /* <RadioButtonGroupInput defaultValue={'portuguese'}
    source="language" label="Idioma" choices={languages} /> */

  return (
    <Edit redirect="list" title={<CategoriasTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput
          label="Título"
          fullWidth
          source="title"
          validate={required()}
        />
        <TextInput label="Descrição" fullWidth source="description" />

        {cookies.english === '1' && <>
          <TextInput
            label="Título (Inglês)"
            fullWidth
            source="title_english"
            validate={required()}
          />
          <TextInput label="Descrição (Inglês)" fullWidth source="description_english" />
        </>}

        {cookies.spanish === '1' && <>
          <TextInput
            label="Título (Espanhol)"
            fullWidth
            source="title_spanish"
            validate={required()}
          />
          <TextInput label="Descrição (Espanhol)" fullWidth source="description_spanish" />
        </>}

        {!permissions.isLoading &&
          (permissions.permissions.includes('ROLE_EMPRESAS_LIST') ||
            permissions.permissions.includes('SYS_ADMIN')) && (
            <CategoryTypeGet />
          )}
        <CategoriaPai />
        <ImageInput
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 35MB', { type: 'warning' });
              }
            },
          }}
          label="Imagem (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput label="Desabilitado ?" source="disabled" />
      </SimpleForm>
    </Edit>
  );
};

export const CategoriasCreate = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const [cookies] = useCookies([]);

  // const languages = useMemo(() => {
  //   const list = [
  //     { id: 'portuguese', name: 'Português' },
  //   ];
  //   if (cookies.english === '1') {
  //     list.push({ id: 'english', name: 'Inglês' });
  //   }
  //   if (cookies.spanish === '1') {
  //     list.push({ id: 'spanish', name: 'Espanhol' });
  //   }
  //   return list;
  // }, [cookies.spanish, cookies.english]);

  return (
    <Create redirect="list" {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput
          label="Título"
          fullWidth
          source="title"
          validate={required()}
        />
        <TextInput label="Descrição" fullWidth source="description" />

        {cookies.english === '1' && <>
          <TextInput
            label="Título (Inglês)"
            fullWidth
            source="title_english"
            validate={required()}
          />
          <TextInput label="Descrição (Inglês)" fullWidth source="description_english" />
        </>}

        {cookies.spanish === '1' && <>
          <TextInput
            label="Título (Espanhol)"
            fullWidth
            source="title_spanish"
            validate={required()}
          />
          <TextInput label="Descrição (Espanhol)" fullWidth source="description_spanish" />
        </>}

        {!permissions.isLoading &&
          (permissions.permissions.includes('ROLE_EMPRESAS_LIST') ||
            permissions.permissions.includes('SYS_ADMIN')) && (
            <CategoryTypeGet />
          )}
        <ReferenceInput
          label="Categoria Pai"
          source="category_id"
          reference="categories"
          allowEmpty
        >
          <SelectInput fullWidth optionText="title" label="Categoria Pai" />
        </ReferenceInput>
        <ImageInput
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 35MB', { type: 'warning' });
              }
            },
          }}
          label="Imagem (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput label="Desabilitado ?" source="disabled" />
      </SimpleForm>
    </Create>
  );
};

export default Categorias;
