import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  required,
  useRecordContext,
  useNotify,
  usePermissions,
  Toolbar,
  SaveButton,
  DeleteButton,
  ListButton,
} from 'react-admin';

const AutoresTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return <span>Autor {record ? `"${record.name}"` : ''}</span>;
};

const AutoresFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

export const Autores = (props) => (
  <List
    perPage={25}
    title="Autores do Blog"
    filters={<AutoresFilter />}
    {...props}
  >
    <Datagrid>
      <TextField label="Nome" source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    className="botoesUpdate"
    style={{ justifyContent: 'space-between' }}
  >
    <div
      style={{
        width: '250px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton alwaysEnable />
      <ListButton
        size="medium"
        variant="outlined"
        label="cancelar"
        icon={<CloseIcon />}
      />
    </div>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_BLOG_AUTORES_DELETE')) && (
        <DeleteButton size="medium" />
      )}
  </Toolbar>
);

export const AutoresEdit = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Edit redirect="list" title={<AutoresTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput label="Nome" validate={required()} source="name" fullWidth />
      </SimpleForm>
    </Edit>
  );
};

export const AutoresCreate = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Create redirect="list" {...props} title="Nova Característica">
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput label="Nome" validate={required()} source="name" fullWidth />
      </SimpleForm>
    </Create>
  );
};

export default Autores;
