import simpleRestProvider from 'ra-data-simple-rest';
import { fetchUtils } from 'react-admin';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json, application/zip',
    });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  options.headers.set('company', localStorage.getItem('company'));
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(
  process.env.REACT_APP_API_URL,
  httpClient,
);

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to company images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    if (!file) reject(new Error('No file'));

    if (file && file.src) {
      if ('src' in file) {
        if (file.src.indexOf('blob') === -1) {
          resolve(file.src);
        }
      }

      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file.rawFile);
    } else {
      resolve(file);
    }
  });

const createDoc = (docs) =>
  Promise.all(docs.map(convertFileToBase64))
    .then((base64Pictures) =>
      base64Pictures.map((doc, index) => ({
        src: doc,
        filename: docs[index].filename || docs[index].title,
        ...(docs[index].name && {
          oldName: docs[index].name,
        }),
      })),
    )
    .then((transformedDocs) => transformedDocs);

const createNoWatermark = (newPictures, resource, params, docs) =>
  Promise.all(newPictures.map(convertFileToBase64))
    .then((base64Pictures) =>
      base64Pictures.map((picture64, index) => ({
        src: picture64,
        description: newPictures[index].description,
        showFirst: newPictures[index].destaque,
      })),
    )
    .then((transformedNewPictures) =>
      dataProvider.create(resource, {
        ...params,
        data: {
          ...params.data,
          pictures: [...transformedNewPictures],
          ...(docs && {
            docs,
          }),
        },
      }),
    );

const updateNoWatermark = (newPictures, resource, params, docs) =>
  Promise.all(newPictures.map(convertFileToBase64))
    .then((base64Pictures) =>
      base64Pictures.map((picture64, index) => ({
        src: picture64,
        description: newPictures[index].description,
        showFirst: newPictures[index].destaque,
      })),
    )
    .then((transformedNewPictures) =>
      dataProvider.update(resource, {
        ...params,
        data: {
          ...params.data,
          pictures: [...transformedNewPictures],
          ...(docs && {
            docs,
          }),
        },
      }),
    );

const createWatermark = (newPictures, resource, params, docs) =>
  convertFileToBase64(params.data.watermark).then((watermarkImage) =>
    Promise.all(newPictures.map(convertFileToBase64))
      .then(async (base64Pictures) =>
        base64Pictures.map((picture64, index) => ({
          src: picture64,
          description: newPictures[index].description,
          showFirst: newPictures[index].destaque,
        })),
      )
      .then(async (transformedNewPictures) =>
        dataProvider.create(resource, {
          ...params,
          data: {
            ...params.data,
            pictures: [...transformedNewPictures],
            watermark: { src: watermarkImage },
            ...(docs && {
              docs,
            }),
          },
        }),
      ),
  );

const updateWatermark = (newPictures, resource, params, docs) =>
  convertFileToBase64(params.data.watermark).then((watermarkImage) =>
    Promise.all(newPictures.map(convertFileToBase64))
      .then(async (base64Pictures) =>
        base64Pictures.map((picture64, index) => ({
          src: picture64,
          description: newPictures[index].description,
          showFirst: newPictures[index].destaque,
        })),
      )
      .then(async (transformedNewPictures) =>
        dataProvider.update(resource, {
          ...params,
          data: {
            ...params.data,
            pictures: [...transformedNewPictures],
            watermark: { src: watermarkImage },
            ...(docs && {
              docs,
            }),
          },
        }),
      ),
  );

const myDataProvider = {
  ...dataProvider,

  update: async (resource, params) => {
    if (!params.data.pictures) {
      // fallback to the default implementation
      return dataProvider.update(resource, params);
    }

    if (!Array.isArray(params.data.pictures)) {
      // eslint-disable-next-line no-param-reassign
      params.data.pictures = [params.data.pictures];
    }

    if (params.data.docs) {
      if (!Array.isArray(params.data.docs)) {
        // eslint-disable-next-line no-param-reassign
        params.data.docs = [params.data.docs];
      }
    }

    const newPictures = params.data.pictures;

    // Para utilizar o formData ao invés de base64
    // const formData = new FormData();

    // formData.append('pictures', params.data.pictures);

    // Object.keys(params.data).forEach((param) => {
    //   // when using multiple files
    //   if (param === 'files') {
    //     params.data[param].forEach((file) => {
    //       formData.append('files', file.rawFile);
    //     });
    //   }

    //   // when using multiple pictures
    //   if (param === 'pictures') {
    //     params.data[param].forEach((pictures) => {
    //       formData.append('pictures', pictures.rawFile);
    //     });
    //   }

    //   formData.append(param, params.data[param]);
    // });

    // return httpClient(
    //   `${process.env.REACT_APP_API_URL}/${resource}/${params.id}`,
    //   {
    //     method: 'PUT',
    //     body: formData,
    //   },
    // ).then(({ json }) => ({
    //   data: { ...params.data, id: json.id },
    // }));

    if (params.data.docs) {
      const docs = await createDoc(params.data.docs);

      if (params.data.watermark) {
        return updateWatermark(newPictures, resource, params, docs);
      }

      return updateNoWatermark(newPictures, resource, params, docs);
    }

    if (params.data.watermark) {
      return updateWatermark(newPictures, resource, params);
    }

    return updateNoWatermark(newPictures, resource, params);
  },

  create: async (resource, params) => {
    if (!params.data.pictures) {
      // fallback to the default implementation
      return dataProvider.create(resource, params);
    }

    if (!Array.isArray(params.data.pictures)) {
      // eslint-disable-next-line no-param-reassign
      params.data.pictures = [params.data.pictures];
    }

    if (params.data.docs) {
      if (!Array.isArray(params.data.docs)) {
        // eslint-disable-next-line no-param-reassign
        params.data.docs = [params.data.docs];
      }
    }

    const newPictures = params.data.pictures;

    if (params.data.docs) {
      const docs = await createDoc(params.data.docs);

      if (params.data.watermark) {
        return createWatermark(newPictures, resource, params, docs);
      }

      return createNoWatermark(newPictures, resource, params, docs);
    }

    if (params.data.watermark) {
      return createWatermark(newPictures, resource, params);
    }

    return createNoWatermark(newPictures, resource, params);

    //  let docs;
    //  let watermark;
    //  let pictures;

    //  if (params.data.docs) {
    //    docs = await createDoc(params.data.docs);
    //  }

    //  if (params.data.pictures) {
    //    pictures = await createPictures(params.data.pictures);
    //  }

    //  if (params.data.watermark) {
    //    watermark = await createPictures(params.data.watermark);
    //  }

    //  return dataProvider.create(resource, {
    //    ...params,
    //    data: {
    //      ...params.data,
    //      ...(pictures && {
    //        pictures,
    //      }),
    //      ...(watermark && {
    //        src: watermark,
    //      }),
    //      ...(docs && {
    //        docs,
    //      }),
    //    },
    //  });
  },
};

export default myDataProvider;
