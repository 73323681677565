import {
  RichTextInput,
  RichTextInputToolbar,
  FormatButtons,
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
} from 'ra-input-rich-text';
import React from 'react';
import {
  TextInput,
  ImageInput,
  BooleanInput,
  NumberInput,
  FileInput,
  DateInput,
} from 'react-admin';

const File = () => (
  <FileInput
    label="Arquivos da Seção (pdf, doc, docx, xls, xlsx, ppt, pptx, odt, ods, odp)"
    source="files"
    multiple
    accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.presentation"
    disabled
    options={{
      disabled: true,
    }}
  />
);

const Image = () => (
  <ImageInput
    label="Imagens da Seção"
    accept="image/*"
    source="pictures"
    multiple
    disabled
    options={{
      disabled: true,
    }}
  />
);

const FileOne = () => (
  <FileInput
    label="Arquivo da Seção (pdf, doc, docx, xls, xlsx, ppt, pptx, odt, ods, odp)"
    source="files"
    accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.presentation"
    disabled
    options={{
      disabled: true,
    }}
  />
);

const ImageOne = () => (
  <ImageInput
    label="Imagem da Seção"
    accept="image/*"
    source="pictures"
    disabled
    options={{
      disabled: true,
    }}
  />
);

const Text = () => <TextInput label="Texto da Seção" source="texto" disabled />;

const Boolean = () => <BooleanInput label="Ativo" source="disabled" disabled />;

const RichText = () => (
  <RichTextInput
    toolbar={
      <RichTextInputToolbar>
        <LevelSelect />
        <AlignmentButtons />
        <FormatButtons />
        <ColorButtons />
        <ListButtons />
        <LinkButtons />
        <QuoteButtons />
        <ClearButtons />
      </RichTextInputToolbar>
    }
    label="Texto da Seção"
    source="richText"
    fullWidth
    disabled
  />
);

const Number = () => (
  <NumberInput label="Número da Seção" source="number" disabled />
);

const Date = () => (
  <DateInput
    label="Data Inicial"
    source="date"
    format={(data) => data && new Date(data).toISOString().split('T')[0]}
    disabled
  />
);

export {
  Text,
  Number,
  RichText,
  Image,
  File,
  Boolean,
  Date,
  ImageOne,
  FileOne,
};
