import { format } from 'date-fns';
import React from 'react';
import {
  List,
  Datagrid,
  TextInput,
  Filter,
  BulkDeleteButton,
  TextField,
  usePermissions,
  useRecordContext,
} from 'react-admin';

const EmailsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_EMAILS_DELETE')) && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

const CustomField = () => {
  const record = useRecordContext();
  const showData = new Date(record.createdAt);
  return <span>{format(showData, 'dd/MM/yyyy HH:mm:ss')}</span>;
};

export const Emails = (props) => {
  const permissions = usePermissions();

  return (
    <List
      perPage={25}
      title="Emails"
      filters={<EmailsFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
    >
      <Datagrid
        bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      >
        <TextField label="Nome" source="name" />
        <TextField label="Telefone" source="phone" />
        <TextField label="Email" source="email" />
        <TextField label="Observação" source="observation" />
        <CustomField label="Data de Cadastro" source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default Emails;
