import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { AutocompleteInput, useGetList, required } from 'react-admin';

import CategoriaRapida from './CategoriaRapida';

const CategoriaNew = () => {
  const { data, isLoading } = useGetList('blog_categories', {
    target: 'category',
    company: localStorage.getItem('company'),
    pagination: { page: 1, perPage: 100 },
  });

  const handleChange = (...res) => {
    data.push(...res);
  };

  if (isLoading) return <CircularProgress />;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '50px',
        width: '100%',
      }}
    >
      <AutocompleteInput
        fullWidth
        validate={required()}
        label="Categoria"
        source="category"
        choices={data}
        style={{ marginRight: '5px' }}
        optionText={(choice) => `${choice.name}`}
      />

      <CategoriaRapida onChange={handleChange} />
    </div>
  );
};

export default CategoriaNew;
