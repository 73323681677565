import CloseIcon from '@mui/icons-material/Close';
import {
  RichTextInput,
  RichTextInputToolbar,
  FormatButtons,
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
} from 'ra-input-rich-text';
import React from 'react';
import {
  List,
  Datagrid,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ImageInput,
  ImageField,
  Filter,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  TextField,
  BooleanInput,
  usePermissions,
  useRecordContext,
  CreateButton,
  useListContext,
  TopToolbar,
  useNotify,
  ListButton,
} from 'react-admin';

import { IMAGE_MAX_SIZE } from '../../helpers/constants';
import ToggleNewsletter from './toggleNewsletter';

const NewslettersTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return <span>Newsletter {record ? `${record.title}` : ''}</span>;
};

const NewslettersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_NEWSLETTER_DELETE')) && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

const ListActions = () => {
  const { total, isLoading } = useListContext();

  return (
    <TopToolbar>{!isLoading && total === 0 && <CreateButton />}</TopToolbar>
  );
};

export const Newsletters = (props) => {
  const permissions = usePermissions();

  return (
    <List
      perPage={25}
      title="Newsletters"
      filters={<NewslettersFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
      {...props}
    >
      <Datagrid
        bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      >
        <ToggleNewsletter label="Ativo" source="disabled" {...props} />
        <ImageField
          sortable={false}
          source="image"
          label="Imagem"
          title="Newsletter"
        />
        <TextField label="Título" source="title" />
        {!permissions.isLoading &&
          (permissions.permissions.includes('SYS_ADMIN') ||
            permissions.permissions.includes('ROLE_NEWSLETTER_UPDATE')) && (
            <EditButton />
          )}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    className="botoesUpdate"
    style={{ justifyContent: 'space-between' }}
  >
    <div
      style={{
        width: '250px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton alwaysEnable />
      <ListButton
        size="medium"
        variant="outlined"
        label="cancelar"
        icon={<CloseIcon />}
      />
    </div>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_NEWSLETTER_DELETE')) && (
        <DeleteButton size="medium" />
      )}
  </Toolbar>
);

export const NewslettersEdit = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Edit redirect="list" title={<NewslettersTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput
          label="Título"
          fullWidth
          source="title"
          validate={required()}
        />
        <RichTextInput
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <AlignmentButtons />
              <FormatButtons />
              <ColorButtons />
              <ListButtons />
              <LinkButtons />
              <QuoteButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          fullWidth
          label="Descrição"
          source="description"
          validate={required()}
        />
        <ImageInput
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 35MB', { type: 'warning' });
              }
            },
          }}
          label="Imagem (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput label="Desabilitado ?" source="disabled" />
      </SimpleForm>
    </Edit>
  );
};

export const NewslettersCreate = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Create redirect="list" {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput
          label="Título"
          fullWidth
          source="title"
          validate={required()}
        />
        <RichTextInput
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <AlignmentButtons />
              <FormatButtons />
              <ColorButtons />
              <ListButtons />
              <LinkButtons />
              <QuoteButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          fullWidth
          label="Descrição"
          source="description"
          validate={required()}
        />
        <ImageInput
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 35MB', { type: 'warning' });
              }
            },
          }}
          label="Imagem (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput label="Desabilitado ?" source="disabled" />
      </SimpleForm>
    </Create>
  );
};

export default Newsletters;
