/* eslint-disable no-multi-assign */
/* eslint-disable no-plusplus */
import MessageIcon from '@mui/icons-material/Message';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import { FileField, useRecordContext } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import ModalFilename from './ModalFilename';

const FilePreview = (props) => {
  const record = useRecordContext(props);
  if (!record.filename) {
    record.filename = record.rawFile ? record.rawFile.name : record.name;
  }

  const [open, setOpen] = useState(false);
  const { filename, src } = record;
  const docs = useWatch({ name: 'docs' });
  const { setValue } = useFormContext();
  let ext = '';

  if (record.rawFile) {
    ext = record.rawFile.name.split('.').pop();
  } else {
    ext = filename.split('.').pop();
  }

  if (record.undefined) {
    record.src = record.undefined;
    delete record.undefined;
  }

  const openModal = () => {
    setOpen(true);
  };

  const handleClose = (tipo, filename) => {
    // Remove caracteres inválidos do nome do arquivo
    filename = filename?.replace(/[/\\?%*:|"<>]/g, '');

    if (tipo === 'salvar') {
      const temp = [...docs];

      temp.forEach((antigo) => {
        if (antigo.src === src) {
          antigo.filename = `${filename}.${ext}`;
        }
      });
      setValue('docs', docs);
    }
    setOpen(false);
  };

  return (
    <>
      <IconButton
        className="filenameButton"
        onClick={() => openModal(record.src)}
        aria-label="Renomear"
        title="Renomear"
        style={{ color: '#0072ff', padding: '12px', marginRight: '6px' }}
      >
        <MessageIcon />
      </IconButton>
      {(record.rawFile && <span>{filename}</span>) || (
        <FileField
          source="src"
          src="src"
          record={record}
          title="filename"
          target="_blank"
          download={false}
        />
      )}
      <ModalFilename
        open={open}
        handleClose={handleClose}
        filenameDefault={filename.split('.').shift()}
      />
    </>
  );
};
export default FilePreview;
