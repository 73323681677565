import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { AutocompleteInput, useGetList, required } from 'react-admin';

import CaracteristicaRapida from './CaracteristicaRapida';

const CaracteristicaNew = ({ source }) => {
  const { data, isLoading } = useGetList('characteristics', {
    target: 'characteristic',
    company: localStorage.getItem('company'),
    pagination: { page: 1, perPage: 100 },
  });

  const handleChange = (...res) => {
    data.push(...res);
  };

  if (isLoading) return <CircularProgress />;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '50px',
        width: '340px',
      }}
    >
      <AutocompleteInput
        fullWidth
        validate={required()}
        label="Caracteristica"
        source={source}
        choices={data}
        style={{ marginRight: '5px' }}
        optionText={(choice) => `${choice.name}`}
      />
      <CaracteristicaRapida onChange={handleChange} source={source} />
    </div>
  );
};

export default CaracteristicaNew;
