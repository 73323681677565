import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

const ImagePreview = (props) => {
  const record = useRecordContext(props);

  if (!record.destaque) record.destaque = false;
  const { description, destaque, src } = record;
  const imagens = useWatch({ name: 'pictures' });
  const { setValue } = useFormContext();

  if (record.undefined) {
    record.src = record.undefined;
    delete record.undefined;
  }

  const setDestaque = () => {
    imagens.forEach((imagem) => {
      if (imagem.src === src) {
        imagem.destaque = !imagem.destaque;
      }
    });
    setValue('pictures', imagens);
  };

  return (
    <>
      <div style={{ padding: '8px' }}>
        <IconButton
          className="showFirstButton"
          onClick={() => setDestaque()}
          aria-label="Destaque"
          title="Destaque"
          style={{
            color: 'yellow',
            margin: '3px 40px 3px 3px',
            padding: '0',
            backgroundColor: '#0000004d',
          }}
        >
          {destaque ? (
            <StarIcon style={{ margin: '2px' }} />
          ) : (
            <StarBorderIcon style={{ margin: '2px' }} />
          )}
        </IconButton>
        <img src={src} style={{ maxHeight: '9rem' }} alt={description} />
      </div>
    </>
  );
};

export default ImagePreview;
