import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        margin: 'normal',
        variant: 'filled',
      },
    },
    MuiSelect: {
      defaultProps: {
        margin: 'none',
        variant: 'filled',
      },
    },
  },
  palette: {
    common: {
      black: 'rgba(30, 30, 30, 1)',
      white: '#fff',
    },
    background: {
      paper: 'rgba(255, 255, 255, 1)',
      default: 'rgba(238, 238, 238, 1)',
    },
    primary: {
      light: '#3E4095',
      main: '#3E4095',
      dark: '#3E4095',
      contrastText: '#fff',
    },
    secondary: {
      light: '#3E4095',
      main: '#3E4095',
      dark: '#3E4095',
      contrastText: '#FFF',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(54, 54, 54, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
});

export default theme;
