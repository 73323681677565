import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  required,
  useRecordContext,
  useNotify,
  usePermissions,
  Toolbar,
  SaveButton,
  DeleteButton,
  ListButton,
} from 'react-admin';
import { useCookies } from 'react-cookie';

const CategoriasTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return <span>Categoria {record ? `"${record.name}"` : ''}</span>;
};

const CategoriasFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const IfCookie = ({ condition, element }) => {
  const [cookies] = useCookies([]);
  if (!cookies) return null;
  return (cookies[condition] === '1' ?? false) ? element : <></>;
};

export const CategoriasBlog = (props) => {
  const [cookies] = useCookies([]);
  return (
    <List
      perPage={25}
      title="Categorias do Blog"
      filters={<CategoriasFilter />}
      {...props}
    >
      <Datagrid>
        <TextField label="Nome" source="name" />
        {cookies.english === '1' && <TextField label="Nome (Inglês)" source="name_english" />}
        {cookies.spanish === '1' && <TextField label="Nome (Espanhol)" source="name_spanish" />}
        <EditButton />
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    className="botoesUpdate"
    style={{ justifyContent: 'space-between' }}
  >
    <div
      style={{
        width: '250px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton alwaysEnable />
      <ListButton
        size="medium"
        variant="outlined"
        label="cancelar"
        icon={<CloseIcon />}
      />
    </div>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_BLOG_CATEGORIAS_DELETE')) && (
        <DeleteButton size="medium" />
      )}
  </Toolbar>
);

export const CategoriasBlogEdit = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Edit redirect="list" title={<CategoriasTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput label="Nome" validate={required()} source="name" fullWidth />
        <IfCookie condition="english"
          element={<TextInput sx={{ mr: 2 }} label="Nome Inglês" source="name_english" fullWidth />}
        />
        <IfCookie condition="spanish"
          element={<TextInput sx={{ mr: 2 }} label="Nome Espanhol" source="name_spanish" fullWidth />}
        />
      </SimpleForm>
    </Edit>
  );
};

export const CategoriasBlogCreate = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Create redirect="list" {...props} title="Nova Categoria">
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput label="Nome" validate={required()} source="name" fullWidth />
        <IfCookie condition="english"
          element={<TextInput sx={{ mr: 2 }} label="Nome Inglês" source="name_english" fullWidth />}
        />
        <IfCookie condition="spanish"
          element={<TextInput sx={{ mr: 2 }} label="Nome Espanhol" source="name_spanish" fullWidth />}
        />
      </SimpleForm>
    </Create>
  );
};

export default CategoriasBlog;
