// eslint-disable-next-line import/order, import/no-unresolved, import/no-webpack-loader-syntax
import background from '!file-loader!../../assets/images/background-login.svg';
import { ArrowBack, NavigateNext } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  CssBaseline,
} from '@mui/material';
import React, { useState } from 'react';
import {
  useNotify,
  Notification,
  useRedirect,
  useAuthProvider,
} from 'react-admin';
import { Link } from 'react-router-dom';

import Copyright from '../../components/Copyright';

export default function ResetarSenha() {
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showCode, setShowCode] = useState(true);
  const [showPasswod, setShowPassword] = useState(false);
  const token = window.location.href.split('?')[1];
  const notify = useNotify();
  const redirect = useRedirect();
  const authProvider = useAuthProvider();

  if (!token) {
    redirect('/login');
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!password || !newPassword || password !== newPassword) {
      notify('As senhas não coincidem', 'warning');
    } else if (password === newPassword) {
      if (password.length < 6) {
        notify('A senha precisa ser maior do que 6 caracteres', 'warning');
      } else {
        authProvider.novaSenha({ token, password }).then(
          () => {
            notify(
              'Sua senha foi alterada com sucesso, faça o login novamente!',
            );
            redirect('/login');
          },
          () => {
            notify(
              'Código inválido, verifique seu E-mail novamente!',
              'warning',
            );
            redirect('/login');
          },
        );
      }
    }
  };

  const controlaCodigo = (e) => {
    e.preventDefault();
    if (!code) {
      notify('Digite o código enviado para o seu E-mail', 'warning');
    } else {
      authProvider.verificaCodigo({ code }).then(
        () => {
          setShowCode(false);
          setShowPassword(true);
        },
        () => {
          notify('Código inválido, verifique novamente seu E-mail', 'warning');
          redirect('/login');
        },
      );
    }
  };

  return (
    <Grid container component="main" style={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'gray',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Avatar style={{ margin: '10px' }}>
          <Link
            to="/login"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            <ArrowBack />
          </Link>
        </Avatar>
        <div
          style={{
            margin: '64px 32px 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Esqueci minha Senha
          </Typography>
          <form
            style={{
              width: '100%',
              marginTop: '8px',
            }}
            onSubmit={controlaCodigo}
            noValidate
          >
            <div style={{ display: showCode ? 'block' : 'none' }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="code"
                label="Código de Verificação"
                name="code"
                type="text"
                autoFocus
                value={code}
                onChange={(e) => setCode(e.target.value.toUpperCase())}
              />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button color="primary" variant="contained" type="submit">
                  <NavigateNext />
                </Button>
              </div>
            </div>
          </form>
          <form
            style={{
              width: '100%',
              marginTop: '8px',
            }}
            onSubmit={handleSubmit}
            noValidate
          >
            <div style={{ display: showPasswod ? 'block' : 'none' }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label="Nova Senha"
                name="password"
                autoComplete="password"
                type="password"
                autoFocus
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="newPassword"
                label="Confirmar Nova Senha"
                name="newPassword"
                type="password"
                autoComplete="new-password"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ margin: '24px 0px 16px' }}
                >
                  Resetar Senha
                </Button>
              </div>
            </div>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
          <Notification />
        </div>
      </Grid>
    </Grid>
  );
}
