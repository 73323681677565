import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  BooleanInput,
  usePermissions,
  useRecordContext,
  Filter,
  ReferenceField,
  AutocompleteInput,
  useGetList,
  FormDataConsumer,
  useNotify,
  ListButton,
} from 'react-admin';

import ToggleSecaoEmpresa from './toggleSecaoEmpresa';

const SecaoEmpresaTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return <span>Seção {record ? `"${record.title}"` : ''}</span>;
};

const SecoesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {!permissions.isLoading &&
      permissions.permissions.includes('SYS_ADMIN') && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

export const SecaoEmpresa = (props) => {
  const permissions = usePermissions();

  return (
    <List
      perPage={25}
      title="Linkar Seção (ADMIN)"
      filters={<SecoesFilter />}
      {...props}
    >
      <Datagrid
        bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      >
        <TextField label="ID" source="id" />
        <ToggleSecaoEmpresa label="Ativo" source="disabled" {...props} />
        <TextField label="Título" source="title" />
        <TextField label="Link da Página" source="page" />
        <ReferenceField
          label="Seção"
          source="section"
          reference="sections"
          linkType="show"
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          label="Empresa"
          source="company"
          reference="companies"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Seção Pai"
          source="upper_section"
          reference="company_sections"
          linkType="show"
        >
          <TextField source="title" />
        </ReferenceField>
        {!permissions.isLoading &&
          permissions.permissions.includes('SYS_ADMIN') && <EditButton />}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    className="botoesUpdate"
    style={{ justifyContent: 'space-between' }}
  >
    <div
      style={{
        width: '250px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton alwaysEnable />
      <ListButton
        size="medium"
        variant="outlined"
        label="cancelar"
        icon={<CloseIcon />}
      />
    </div>
    {permissions &&
      permissions.permissions &&
      permissions.permissions.includes('SYS_ADMIN') && (
        <DeleteButton size="medium" />
      )}
  </Toolbar>
);

const SubsectionsGet = () => {
  const { data, isLoading } = useGetList('company_sections', {
    target: 'upper_sections',
    pagination: { page: 1, perPage: 100 },
    filter: {
      company: localStorage.getItem('company'),
    },
  });

  if (isLoading) return <CircularProgress />;

  let outraEmpresa = false;
  data.forEach((item) => {
    if (item.company !== parseInt(localStorage.getItem('company'), 10)) {
      outraEmpresa = true;
    }
  });

  if (outraEmpresa) return null;

  return (
    <AutocompleteInput
      fullWidth
      source="upper_section"
      label="Seção Pai"
      choices={data}
      optionText={(choice) => `${choice.title}`}
    />
  );
};

const SectionsGet = () => {
  const { data, isLoading } = useGetList('sections', {
    target: 'sections',
    pagination: { page: 1, perPage: 100 },
  });

  if (isLoading) return <CircularProgress />;

  return (
    <AutocompleteInput
      fullWidth
      validate={required()}
      source="section"
      label="Seção"
      choices={data}
      optionText={(choice) => `${choice.title}`}
    />
  );
};

const EmpresasGet = () => {
  const { data, isLoading } = useGetList('companies', {
    target: 'companies',
    pagination: { page: 1, perPage: 100 },
  });

  if (isLoading) return <CircularProgress />;

  return (
    <AutocompleteInput
      fullWidth
      validate={required()}
      source="company"
      label="Empresa"
      choices={data}
      optionText={(choice) => `${choice.name}`}
    />
  );
};

export const SecaoEmpresaEdit = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Edit redirect="list" title={<SecaoEmpresaTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput source="title" label="Nome da Seção" validate={required()} />
          <FormDataConsumer>
          {({ formData }) => (
            <TextInput
              label="Link da Página"
              source="page"
              validate={
                Number.isNaN(parseInt(formData.upper_section, 10))
                  ? required()
                  : null
              }
              format={(value) =>
                value?.replace(/[^A-Z0-9_-]/gi, '')?.toLowerCase() ?? ''
              }
            />
          )}
        </FormDataConsumer>
        <SectionsGet />
        <SubsectionsGet />
        <EmpresasGet />
        <BooleanInput source="showIndex" label="Mostrar na Index?" />
        <BooleanInput source="singular" label="Apenas um?" />
        <BooleanInput source="disabled" label="Desabilitar seção?" />
      </SimpleForm>
    </Edit>
  );
};

export const SecaoEmpresaCreate = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Create redirect="list" {...props} title="Nova Seção">
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput source="title" label="Nome da Seção" validate={required()} />
        <FormDataConsumer>
          {({ formData }) => (
            <TextInput
              label="Link da Página"
              source="page"
              validate={
                Number.isNaN(parseInt(formData.upper_section, 10))
                  ? required()
                  : null
              }
              format={(value) =>
                value?.replace(/[^A-Z0-9_-]/gi, '')?.toLowerCase() ?? ''
              }
            />
          )}
        </FormDataConsumer>
        <SectionsGet />
        <SubsectionsGet />
        <EmpresasGet />
        <BooleanInput source="showIndex" label="Mostrar na Index?" />
        <BooleanInput source="singular" label="Apenas um?" />
        <BooleanInput source="disabled" label="Desabilitar seção?" />
      </SimpleForm>
    </Create>
  );
};

export default SecaoEmpresa;
