import CloseIcon from '@mui/icons-material/Close';
import {
  RichTextInput,
  RichTextInputToolbar,
  FormatButtons,
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
} from 'ra-input-rich-text';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  required,
  useRecordContext,
  useNotify,
  Toolbar,
  SaveButton,
  DeleteButton,
  ListButton,
  usePermissions,
} from 'react-admin';

import ShortField from '../../components/ShortField';

const DepoimentosTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return <span>Depoimento de {record ? `"${record.name}"` : ''}</span>;
};

const DepoimentosFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

export const Depoimentos = (props) => (
  <List
    perPage={25}
    title="Depoimentos"
    filters={<DepoimentosFilter />}
    {...props}
  >
    <Datagrid>
      <TextField label="Nome" source="name" />
      <ShortField label="Descrição" source="description" />
      <EditButton />
    </Datagrid>
  </List>
);

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    className="botoesUpdate"
    style={{ justifyContent: 'space-between' }}
  >
    <div
      style={{
        width: '250px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton alwaysEnable />
      <ListButton
        size="medium"
        variant="outlined"
        label="cancelar"
        icon={<CloseIcon />}
      />
    </div>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_DEPOIMENTOS_DELETE')) && (
        <DeleteButton size="medium" />
      )}
  </Toolbar>
);

export const DepoimentosEdit = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Edit redirect="list" title={<DepoimentosTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput label="Nome" validate={required()} source="name" fullWidth />
        <RichTextInput
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <AlignmentButtons />
              <FormatButtons />
              <ColorButtons />
              <ListButtons />
              <LinkButtons />
              <QuoteButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          validate={required()}
          label="Descrição"
          source="description"
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export const DepoimentosCreate = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Create redirect="list" {...props} title="Novo Depoimento">
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput label="Nome" validate={required()} source="name" fullWidth />
        <RichTextInput
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <AlignmentButtons />
              <FormatButtons />
              <ColorButtons />
              <ListButtons />
              <LinkButtons />
              <QuoteButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          validate={required()}
          label="Descrição"
          source="description"
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

export default Depoimentos;
