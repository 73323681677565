import { CssBaseline, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Outlet } from 'react-router-dom';

import Background from '../../assets/images/background-login.svg';
import FormLogin from './login';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
};

const StyledGrid = styled(Grid)((
  {
    theme,
  },
) => ({
  [`&.${classes.root}`]: {
    height: '100vh',
  },

  [`& .${classes.image}`]: {
    backgroundImage: `url('data:image/svg+xml;utf8,${Background}')`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  [`& .${classes.paper}`]: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  [`& .${classes.form}`]: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  return (
    (<StyledGrid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <FormLogin />
      <Outlet />
    </StyledGrid>)
  );
}
